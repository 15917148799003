import React, { useCallback, useState } from "react";
import { graphql } from "gatsby";
import { getTranslations } from "../helpers/translations";

import Page from "../components/Page";
import DynamicContainer, {
  DynamicContainerProps,
} from "../components/DynamicContainer";
import { mapSections } from "../helpers/dynamic";
import { useIntl } from "../hooks/useIntl";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";

const Dynamic = ({ data }: { data: any }) => {
  const intl = useIntl();
  const [pageUrl, setPageUrl] = useState(
    data?.directus?.dynamic_page_by_id?.page_slug
  );

  const getData = useCallback(() => {
    return (
      getTranslations(
        data,
        "directus.dynamic_page_by_id.translations",
        intl.locale
      ) ?? {}
    );
  }, [data, intl]);

  const getSections = useCallback((): DynamicContainerProps => {
    return {
      sections: mapSections(getData()?.sections_list),
    };
  }, [getData]);

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={
        <SEO
          {...parseSEO(getData(), intl?.locale)}
          withChatBot={
            intl.locale === "en-US" && pageUrl === "faqs" || pageUrl === "mission" ? true : false
          }
        />
      }
    >
      <DynamicContainer {...getSections()} />
    </Page>
  );
};

export default Dynamic;

export const query = graphql`
  query ($id: ID!, $locale: String) {
    directus {
      dynamic_page_by_id(id: $id) {
        sort
        status
        page_slug
        path
        id
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          id
          languages_code {
            code
            name
          }
          status
          sections_list {
            sort
            id
            collection
            collection_id {
              __typename
              ... on Directus_featured_content_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  left_content_eyebrow
                  left_content_image {
                    id
                    filename_disk
                  }
                  left_content_image_alt
                  left_content_title
                  right_content_button_label
                  right_content_button_link
                  right_content_description
                  right_content_title
                  status
                }
              }
              ... on Directus_html_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  html_status
                  background_color
                  type
                  content
                  video_url
                  unsafe_code
                }
              }
              ... on Directus_accordion_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  elements
                  accordion_status
                }
              }
              ... on Directus_banner_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  banner_status
                  title
                  description
                  background_color
                  title_font_size
                  font_color
                  button_label
                  button_link
                  type
                  images {
                    id
                    banner_asset_id {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        banner_asset_status
                        image_alt
                        image {
                          id
                          filename_disk
                        }
                      }
                    }
                  }
                }
              }
              ... on Directus_destini_locator {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  deflang
                  defctry
                  destini_locator_status
                  destini_override_url
                  auto_search
                }
              }
              ... on Directus_coupon {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  coupon_status
                  title
                  print_label
                  image_alt
                  image {
                    id
                    filename_disk
                  }
                  find_store_label
                  find_store_link
                }
              }
              ... on Directus_tabs_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  tabs_section_status
                  tabs {
                    dynamic_tab_section_id {
                      tab_slug
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        dynamic_tab_status
                        tab_title
                        tab_content {
                          collection
                          item {
                            __typename
                            ... on Directus_html_section {
                              element_slug
                              translations(
                                filter: {
                                  languages_code: { code: { _eq: $locale } }
                                }
                              ) {
                                languages_code {
                                  code
                                  name
                                }
                                html_status
                                content
                              }
                            }
                            ... on Directus_accordion_section {
                              element_slug
                              translations(
                                filter: {
                                  languages_code: { code: { _eq: $locale } }
                                }
                              ) {
                                languages_code {
                                  code
                                  name
                                }
                                elements
                                accordion_status
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Directus_featured_recipes_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  id
                  status
                  title
                  background_color
                  header_color
                  card_background_color
                  card_text_color
                  card_hover_text_color
                  card_label_color
                  card_hover_background_color
                  link_label
                  link_url
                  recipes_list {
                    recipe_page_id {
                      recipe_page_slug
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          code
                          name
                        }
                        recipe_content_status
                        recipe {
                          product_page {
                            product_page_slug
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              languages_code {
                                code
                                name
                              }
                              variants_status
                              product {
                                translations(
                                  filter: {
                                    languages_code: { code: { _eq: $locale } }
                                  }
                                ) {
                                  languages_code {
                                    code
                                    name
                                  }
                                  product_name
                                }
                              }
                            }
                          }
                          translations(
                            filter: {
                              languages_code: { code: { _eq: $locale } }
                            }
                          ) {
                            languages_code {
                              code
                              name
                            }
                            status
                            title
                            author
                            serves_value
                            prep_time_value
                            cook_time_value
                            image {
                              id
                              filename_disk
                            }
                            image_alt
                            left_tab_content
                            right_tab_content
                            video_url
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on Directus_multiple_fields_form_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  id
                  disclaimer
                  title
                  background_color
                  description
                  status
                  klaviyo_id
                  formstack_id
                  form_name
                  button_label
                  fields
                  success_message
                  sticky
                  sticky_label
                  step_1_title
                  step_2_title
                  success_headline
                }
              }
              ... on Directus_single_field_form_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  klaviyo_id
                  disclaimer
                  formstack_id
                  placeholder
                  formstack_field_id
                  form_name
                  success_message
                  button_label
                  title
                  label
                  key
                  sticky
                  sticky_label
                }
              }
              ... on Directus_featured_recipe_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  title
                  status
                  description
                  recipe {
                    recipe_page_slug
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        code
                        name
                      }
                      status
                      recipe_content_status
                      recipe {
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          title
                          image {
                            id
                            filename_disk
                          }
                          image_alt
                        }
                      }
                    }
                  }
                }
              }
              ... on Directus_faq_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  title
                  subtitle
                  cards_list
                }
              }
              ... on Directus_featured_article_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  title
                  featured_article {
                    article_slug
                    article_type {
                      type_value
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          name
                          code
                        }
                        article_type_label
                      }
                    }
                    id
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        name
                        code
                      }
                      status
                      tags {
                        article_tag_id {
                          tag_type {
                            type_value
                            translations(
                              filter: {
                                languages_code: { code: { _eq: $locale } }
                              }
                            ) {
                              languages_code {
                                name
                                code
                              }
                              article_type_label
                            }
                          }
                          translations(
                            filter: {
                              languages_code: { code: { _eq: $locale } }
                            }
                          ) {
                            languages_code {
                              name
                              code
                            }
                            tag_name
                          }
                        }
                      }
                      info_status
                      article_title
                      article_description
                      article_date
                      article_image_alt
                      article_image {
                        id
                        filename_disk
                      }
                      article_thumbnail_alt
                      article_thumbnail {
                        id
                        filename_disk
                      }
                      article_file {
                        id
                        filename_disk
                      }
                      article_content
                      article_questions_title
                      article_questions_accordion
                    }
                  }
                }
              }
              ... on Directus_cards_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  title
                  cards {
                    dynamic_card_id {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        status
                        languages_code {
                          name
                          code
                        }
                        title
                        image {
                          id
                          filename_disk
                        }
                        image_alt
                        button_label
                        button_link
                        input_placeholder
                      }
                    }
                  }
                }
              }
              ... on Directus_image_with_text_section {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  title
                  description
                  image {
                    id
                    filename_disk
                  }
                  image_alt
                  button_label
                  button_link
                }
              }
              ... on Directus_hero_with_five_images {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  background_color
                  title
                  subtitle
                  description
                  top_big_image {
                    id
                    filename_disk
                  }
                  top_big_image_alt
                  top_medium_image {
                    id
                    filename_disk
                  }
                  top_medium_image_alt
                  top_small_image {
                    id
                    filename_disk
                  }
                  top_small_image_alt
                  bottom_big_image {
                    id
                    filename_disk
                  }
                  bottom_big_image_alt
                  bottom_medium_image {
                    id
                    filename_disk
                  }
                  bottom_medium_image_alt
                }
              }
              ... on Directus_items_collapsibles {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  background_color
                  title
                  accordion_elements {
                    id
                    meat_from_plants_asset_id {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          name
                          code
                        }
                        description
                        status
                        title
                        image_alt
                        image {
                          id
                          filename_disk
                        }
                      }
                    }
                  }
                }
              }
              ... on Directus_dynamic_carousel {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  background_color
                  styles
                  title
                  description
                  cta_label
                  cta_link
                  carousel_elements
                  images {
                    id
                    dynamic_carousel_assets_id {
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          name
                          code
                        }
                        status
                        alt
                        link
                        name {
                          id
                          filename_disk
                        }
                      }
                    }
                  }
                }
              }
              ... on Directus_text_with_three_images {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  background_color
                  title
                  description
                  cta_label
                  cta_link
                  top_image {
                    id
                    filename_disk
                  }
                  top_image_alt
                  mid_image {
                    id
                    filename_disk
                  }
                  mid_image_alt
                  bottom_image {
                    id
                    filename_disk
                  }
                  bottom_image_alt
                }
              }
              ... on Directus_text_with_transparent_image {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  background_color
                  styles
                  title
                  description
                  big_image {
                    id
                    filename_disk
                  }
                  big_image_alt
                  transparent_image {
                    id
                    filename_disk
                  }
                  transparent_image_alt
                }
              }
              ... on Directus_search_recipe {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  background_color
                  title
                  description
                  recipe {
                    recipe_page_slug
                    translations(
                      filter: { languages_code: { code: { _eq: $locale } } }
                    ) {
                      languages_code {
                        code
                        name
                      }
                      status
                      recipe_content_status
                      recipe {
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          title
                          image {
                            id
                            filename_disk
                          }
                          image_alt
                        }
                      }
                    }
                  }
                }
              }
              ... on Directus_dynamic_cards {
                element_slug
                translations(
                  filter: { languages_code: { code: { _eq: $locale } } }
                ) {
                  languages_code {
                    code
                    name
                  }
                  status
                  background_color
                  background_color_bottom
                  cards_background_color
                  hover_background_color
                  title
                  additional_text
                  cards {
                    card_with_image_id {
                      element_slug
                      translations(
                        filter: { languages_code: { code: { _eq: $locale } } }
                      ) {
                        languages_code {
                          name
                          code
                        }
                        status
                        title
                        description
                        label_1
                        label_2
                        link
                        small_text
                        image_alt
                        image {
                          id
                          filename_disk
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
