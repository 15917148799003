import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { toREM, toVWMobile, toVWDesktop } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

export type PlainHtmlProps = {
  type?: string;
  content?: string;
  slug: string;
};

const PlainHtml: FC<PlainHtmlProps> = ({ content, slug }: PlainHtmlProps) => {
  const [bulletColor, setBulletColor] = useState<undefined | string>(content);

  useEffect(() => {
    const regex = /color: #[0-9a-fA-F]{6}/;
    const match = content?.match(regex);
    if (match) {
      const hexCode = match[0].split(":")[1].trim();
      setBulletColor(hexCode);
    }
  }, [content]);

  return (
    <Container
      id={slug}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
      bulletColor={bulletColor}
    />
  );
};

const Container = styled.section<{ bulletColor?: string }>`
  &,
  & * {
    font-family: ${(props) => props.theme.fontFamily}, ${(props) =>
  props.theme.fallBackFontFamily}, sans-serif;
    color: #ffffff;
    font-size: ${toREM(18)}rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    line-height: ${toREM(28)}rem;
  }

  .adimo-iframe-outer .adimo-iframe {
    width: 100%;
    border: none;
  }

  h2,
  h3,
  h4 {
    font-weight: 900;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: ${toVWMobile(30)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(31)}vw;
    }
  }

  h2 {
    font-size: ${toREM(44)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(56)}rem;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      font-size: ${toREM(48)}rem;
    }
  }

  h2 span {
    font-size: ${toREM(44)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(56)}rem;
    font-weight: 900;
  }

  h2 a,
  h2 a:link {
    font-size: ${toREM(44)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(56)}rem;
    font-weight: 800;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      font-size: ${toREM(48)}rem;
    }
  }

  h3 {
    font-size: ${toREM(36)}rem;
    letter-spacing: ${toREM(1.08)}rem;
    line-height: ${toVWMobile(40)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      line-height: ${toVWDesktop(40)}vw;
    }
  }

  h3 span {
    font-size: ${toREM(36)}rem;
    letter-spacing: ${toREM(1.08)}rem;
    line-height: ${toVWMobile(40)}vw;
    font-weight: 900;
  }

  h3 a,
  h3 a:link {
    font-size: ${toREM(36)}rem;
    letter-spacing: ${toREM(1.08)}rem;
    line-height: ${toVWMobile(40)}vw;
    font-weight: 800;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      line-height: ${toVWDesktop(40)}vw;
    }
  }

  h4 {
    font-size: ${toREM(24)}rem;
    letter-spacing: ${toREM(1.44)}rem;
    line-height: normal;
  }

  h4 span {
    font-size: ${toREM(24)}rem;
    letter-spacing: ${toREM(1.44)}rem
    line-height: normal;
    font-weight: 900;
  }

  h4 a,
  h4 a:link {
    font-size: ${toREM(24)}rem;
    letter-spacing: ${toREM(1.44)}rem;
    line-height: normal;
    font-weight: 800;
  }

  a {
    color: #ffffff;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-decoration: underline;
  }

  p {
    margin-bottom: ${toVWMobile(46)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(47)}vw;
    }

    a {
      color: #75C154;
    }
  }

  .faqsTitle {
    color: #FFF;
    font-size: ${toREM(55)}rem;
    font-style: normal;
    font-weight: 900;
    line-height: ${toREM(76)}rem;
    letter-spacing: ${toREM(4)}rem;
    text-transform: uppercase;
    margin: 0 0 ${toVWDesktop(16)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      font-size: ${toREM(80)}rem
    }
  }

  .faqsDescription {
    margin: 0;
  }

  ul,
  ol {
    padding-left: ${toVWMobile(18)}vw;
    margin-bottom: ${toVWMobile(70)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding-left: ${toVWDesktop(20)}vw;
      margin-bottom: ${toVWDesktop(66)}vw;
    }
  }

  ul, li::marker {
    color: ${({ bulletColor }) => (bulletColor ? bulletColor : "#033305")};
  } 

  li {
    margin-bottom: ${toVWMobile(34)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(35)}vw;
    }

    ul,
    ol {
      padding-left: ${toVWMobile(50)}vw;
      margin-top: ${toVWMobile(34)}vw;
      margin-bottom: 0;

      @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
        padding-left: ${toVWDesktop(40)}vw;
        margin-top: ${toVWDesktop(35)}vw;
      }
    }
  }

  img {
    height: auto;
    object-fit: contain;
  }

  h2 {
    img {
      width: 100%;
    }
  }

  p { 
    img {
      width: auto;
    }
  }

  .row {
    display: flex;
    margin-bottom: ${toVWMobile(46)}vw;
    gap: ${toVWMobile(5)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(47)}vw;
      gap: ${toVWDesktop(19)}vw;
    }
  }

  .column {
    flex: 1;
  }

  table {
    border: transparent;

    tbody tr {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    tbody tr td {
      width: 100% !important;
      padding: 0 ${toVWMobile(15)}vw;
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      tbody tr td {
        padding: 0 ${toVWDesktop(25)}vw;
      }

      tbody tr {
      flex-direction: row;
    }
    }
  }
`;

export default PlainHtml;
