import { useAppDispatch } from "./useAppDispatch";
import { useEffect } from "react";
import {
  addParallaxElement,
  addParallaxRootElement,
  removeParallaxElement,
  removeParallaxRootElement,
} from "../store/parallax";
import { isMobile } from "react-device-detect";
import { ParallaxAnimation } from "../helpers/parallax";

export const useParallax = ({
  elements,
  containerId,
}: {
  elements: Array<{ animations: ParallaxAnimation[]; id: string }>;
  containerId: string;
}) => {
  const dispatch = useAppDispatch();

  const addElement = (element: {
    animations: ParallaxAnimation[];
    id: string;
  }) => {
    const parallaxElement = document.querySelector(`#${element?.id}`);
    if (parallaxElement) {
      dispatch(
        addParallaxElement({
          element: {
            element: parallaxElement,
            animations: element.animations,
          },
          rootKey: containerId,
          key: element.id,
        })
      );
    }
  };

  const removeElement = (element: {
    animations: ParallaxAnimation[];
    id: string;
  }) => {
    dispatch(
      removeParallaxElement({
        key: element.id,
        rootKey: containerId,
      })
    );
  };

  useEffect(() => {
    const parallaxRootElement = document.querySelector(`#${containerId}`);
    if (parallaxRootElement) {
      dispatch(
        addParallaxRootElement({
          key: containerId,
          element: {
            root: parallaxRootElement,
            rect: parallaxRootElement.getBoundingClientRect(),
            offset: isMobile ? window.innerHeight / 3 : 0,
            elements: {},
          },
        })
      );
    }

    elements.forEach((e) => {
      addElement(e);
    });

    return () => {
      parallaxRootElement && dispatch(removeParallaxRootElement(containerId));
      elements.forEach((e) => {
        removeElement(e);
      });
    };
  }, []);
};
