import React, { FC, useState } from "react";
import styled from "styled-components";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import useRightToLeft from "../../hooks/useRightToLeft";
import { Image } from "../../types";

export type TransparentImageProps = {
  isPublished?: boolean;
  title: string;
  description: string;
  type: string;
  backgroundColor: string;
  bigImage: Image;
  transparentImage: Image;
};

const TransparentImage: FC<TransparentImageProps> = ({
  isPublished,
  title,
  description,
  backgroundColor,
  bigImage,
  transparentImage,
}: TransparentImageProps) => {
  if (!isPublished) return null;

  const isRLT = useRightToLeft();

  const [fontColor, setFontColor] = useState<null | string>(description);

  const regex = /color: #[0-9a-fA-F]{6}/;
  const match = fontColor?.match(regex);
  if (match) {
    const hexCode = match[0].split(":")[1].trim();
    setFontColor(hexCode);
  }

  return (
    <Container backgroundColor={backgroundColor}>
      <ImageContainer>
        <BigImage src={bigImage.src} alt={bigImage.alt} />
      </ImageContainer>
      <TextContainer>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Description
          isRTL={isRLT}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          fontColor={fontColor || ""}
        />
        <BottomImage src={transparentImage.src} alt={transparentImage.alt} />
      </TextContainer>
    </Container>
  );
};

const Container = styled.section<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#7A3818"};
  padding: 0 0 ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(80)}vw ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw
      ${toVWDesktop(205)}vw;
    flex-direction: row-reverse;
    gap: ${toVWDesktop(70)}vw;
  }
`;

const TextContainer = styled.div`
  padding: ${toVWMobile(40)}vw ${toVWMobile(24)}vw 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

const Title = styled.h3<{ fontColor?: string }>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: ${toVWMobile(44)}vw;
  text-align: left;
  text-transform: uppercase;

  a {
    color: #75c154;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    letter-spacing: ${toREM(1.68)}rem;
    line-height: ${toVWDesktop(64)}vw;
    margin-bottom: ${toVWDesktop(15)}vw;
  }
`;

const Description = styled.p<{ fontColor?: string; isRTL?: boolean }>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(24)}vw;
  margin-bottom: ${toVWMobile(28)}vw;
  text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};

  li::marker {
    color: ${(props) => props.fontColor};
  }

  li {
    margin-left: ${toVWMobile(20)}vw;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(24)}vw;
    margin-bottom: ${toVWDesktop(40)}vw;
    text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};

    li::marker {
      color: ${(props) => props.fontColor};
    }

    li {
      margin-left: ${toVWDesktop(20)}vw;
    }
  }
`;

const BottomImage = styled.img`
  display: flex;
  width: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
`;

const BigImage = styled.img`
  display: flex;
  object-fit: cover;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-height: ${toVWMobile(390)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-width: ${toVWDesktop(610)}vw;
    max-height: ${toVWDesktop(564)}vw;
  }
`;

export default TransparentImage;
