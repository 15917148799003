import { isMobile } from "react-device-detect";
import {
  ParallaxAnimation,
  ParallaxTransformations,
} from "../../helpers/parallax";
import { toPixelsFromVW, toVWDesktop, toVWMobile } from "../../helpers/styles";

export const PARALLAX_ELEMENTS_ID = {
  OUR_MISSION_IMAGE_1: "OUR_MISSION_IMAGE_1",
  OUR_MISSION_IMAGE_2: "OUR_MISSION_IMAGE_2",
  OUR_MISSION_IMAGE_3: "OUR_MISSION_IMAGE_3",
  OUR_MISSION_IMAGE_4: "OUR_MISSION_IMAGE_4",
  OUR_MISSION_IMAGE_5: "OUR_MISSION_IMAGE_5",
  OUR_MISSION_IMAGE_6: "OUR_MISSION_IMAGE_6",
};

export const PARALLAX_ELEMENTS: Array<{
  animations: ParallaxAnimation[];
  id: string;
}> = [
  {
    id: PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_1,
    animations: [
      {
        unit: "px",
        from: isMobile
          ? toPixelsFromVW(toVWMobile(-50))
          : toPixelsFromVW(toVWDesktop(-40)),
        to: isMobile
          ? toPixelsFromVW(toVWMobile(250))
          : toPixelsFromVW(toVWDesktop(300)),
        transformation: ParallaxTransformations.TRANSLATE_Y,
      },
    ],
  },
  {
    id: PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_2,
    animations: [
      {
        unit: "px",
        from: isMobile
          ? toPixelsFromVW(toVWMobile(-70))
          : toPixelsFromVW(toVWDesktop(-50)),
        to: isMobile
          ? toPixelsFromVW(toVWMobile(200))
          : toPixelsFromVW(toVWDesktop(200)),
        transformation: ParallaxTransformations.TRANSLATE_Y,
      },
    ],
  },
  {
    id: PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_3,
    animations: [
      {
        unit: "px",
        from: isMobile
          ? toPixelsFromVW(toVWMobile(-50))
          : toPixelsFromVW(toVWDesktop(-600)),
        to: isMobile
          ? toPixelsFromVW(toVWMobile(100))
          : toPixelsFromVW(toVWDesktop(-200)),
        transformation: ParallaxTransformations.TRANSLATE_Y,
      },
    ],
  },
  {
    id: PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_4,
    animations: [
      {
        unit: "px",
        from: isMobile
          ? toPixelsFromVW(toVWMobile(-200))
          : toPixelsFromVW(toVWDesktop(0)),
        to: isMobile
          ? toPixelsFromVW(toVWMobile(100))
          : toPixelsFromVW(toVWDesktop(600)),
        transformation: ParallaxTransformations.TRANSLATE_Y,
      },
    ],
  },
  {
    id: PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_5,
    animations: [
      {
        unit: "px",
        from: isMobile
          ? toPixelsFromVW(toVWMobile(-100))
          : toPixelsFromVW(toVWDesktop(-100)),
        to: isMobile
          ? toPixelsFromVW(toVWMobile(50))
          : toPixelsFromVW(toVWDesktop(400)),
        transformation: ParallaxTransformations.TRANSLATE_Y,
      },
    ],
  },
  {
    id: PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_6,
    animations: [
      {
        unit: "px",
        from: isMobile
          ? toPixelsFromVW(toVWMobile(0))
          : toPixelsFromVW(toVWDesktop(-200)),
        to: isMobile
          ? toPixelsFromVW(toVWMobile(400))
          : toPixelsFromVW(toVWDesktop(100)),
        transformation: ParallaxTransformations.TRANSLATE_Y,
      },
    ],
  },
];

export const PARALLAX_CONTAINER_ID = "OUR_MISSION_CONTAINER";
