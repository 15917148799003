export enum DYNAMIC_TYPE {
  PLAIN_HTML = "html_section",
  ACCORDION = "accordion_section",
  TABS = "tabs_section",
  BANNER = "banner_section",
  BANNER_TYPE_1 = "template_1",
  BANNER_TYPE_2 = "template_2",
  DESTINI_LOCATOR = "destini_locator",
  COUPON = "coupon",
  FEATURED_RECIPES = "featured_recipes_section",
  FEATURED_RECIPE = "featured_recipe_section",
  FEATURED_ARTICLE = "featured_article_section",
  MULTIPLE_FORM = "multiple_fields_form_section",
  SINGLE_FORM = "single_field_form_section",
  FAQ_SECTION = "faq_section",
  FEATURED_CONTENT_SECTION = "featured_content_section",
  CARDS_SECTION = "cards_section",
  IMAGE_WITH_TEXT_SECTION = "image_with_text_section",
  HERO_WITH_FIVE_IMAGES= "hero_with_five_images",
  ITEMS_COLLAPSIBLES= "items_collapsibles",
  DYNAMIC_CAROUSEL= "dynamic_carousel",
  TEXT_WITH_THREE_IMAGES= "text_with_three_images",
  TRANSPARENT_IMAGE= "text_with_transparent_image",
  SEARCH_RECIPE = "search_recipe",
  DYNAMIC_CARDS = "dynamic_cards"
}

export enum HTML_TEMPLATES {
  PLAIN_HTML_TYPE_1 = "template_1",
  PLAIN_HTML_TYPE_2 = "template_2",
}
