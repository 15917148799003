import React, { FC, useState, useCallback, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { vwMobile, vwDesktop, rem } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { KlaviyoService } from "../../services/klaviyo";
import { FormstackService } from "../../services/formstack";
import Button, { ButtonType } from "../shared/Button";
import Input from "../shared/Input/Input";
import Checkbox from "../shared/Checkbox/Checkbox";
import Select from "../StartSelling/Select";
import { US_STATES, CA_STATES } from "../../helpers/states";
import { useMessages } from "../../hooks/useMessages";
import { getErrorMessages, isFormValid } from "../../helpers/forms";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { Floater } from "../../components/DynamicForm/Floater";
import step1Disabled from "./assets/Step1Disabled.svg";
import successImage from "./assets/SuccessIcon.svg";

export type DynamicFormProps = {
  slug?: string;
  isPublished: boolean;
  title?: string;
  description: string;
  backgroundColor: string;
  disclaimer?: string;
  klaviyoId: string;
  formName: string;
  formstackId: string;
  buttonLabel: string;
  fields: {
    label?: string;
    key?: string;
    madatory?: boolean;
    formstackFieldId?: string;
    placeholder?: string;
    type?: string;
    width?: string;
  }[];
  successMessage: string;
  sticky?: boolean;
  stickyLabel?: string;
  step1Label: string;
  step2Label: string;
  successHeadline: string;
};

const DynamicForm: FC<DynamicFormProps> = ({
  title,
  description,
  backgroundColor,
  disclaimer,
  klaviyoId,
  formName,
  formstackId,
  buttonLabel,
  isPublished,
  fields,
  slug,
  successMessage,
  sticky,
  stickyLabel,
  step1Label,
  step2Label,
  successHeadline,
}: DynamicFormProps) => {
  if (!isPublished) return null;

  const getInitialState = () => {
    return fields?.reduce(
      (obj: any, item: any) => (
        (obj[item?.key] = {
          value: "",
          required: item?.mandatory,
          formstackFieldId: item?.formstackFieldId,
        }),
        obj
      ),
      {}
    );
  };

  const recaptchaRef = useRef();
  const [form, setForm] = useState(getInitialState());
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>("step1");
  const messages = useMessages();
  const labels = useStaticLabels();

  const handleChange = (event: React.FormEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    const newForm = { ...form };
    newForm[name].value = value;
    setForm(newForm);
  };

  const nextStep = () => {
    setCurrentStep("step2");
  };

  const previousStep = () => {
    setCurrentStep("step1");
  };

  const handleChangeDefault = (event: React.FormEvent) => {
    const { checked } = event.target as HTMLInputElement;
    setDisclaimerAccepted(checked);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setErrorMessages(getErrorMessages(form, messages));
      if (!isFormValid(form, messages)) {
        setCurrentStep("step1");
        return;
      }
      try {
        setLoading(true);
        await recaptchaRef.current?.executeAsync();
        if (klaviyoId) {
          await KlaviyoService.submitDynamicForm(
            { listId: klaviyoId, formName },
            {
              ...form,
              ...(disclaimerAccepted ? { consent: { value: true } } : {}),
            }
          );
        }
        if (formstackId) {
          await FormstackService.submitDynamicForms({
            form,
            formstackId,
          });
        }
        const tId = setTimeout(() => {
          setSuccess(false);
          setForm(getInitialState());
          clearTimeout(tId);
        }, 10000);
        setCurrentStep("step1");
        setSuccess(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [form, messages, formName, disclaimerAccepted]
  );

  const fieldWidth = {
    full: "100%",
    twothirds: "68%",
    half: "48.6%",
    onethird: "29%",
  };

  const stickyFields = (field, name, index) => (
    <>
      {field.type === "us_states" ? (
        <StickySelectCustomWidth
          customWidth={fieldWidth[field.width] || "full"}
        >
          <Select
            key={index}
            aria-label={field?.placeholder}
            placeHolder={field?.placeholder}
            name={name}
            value={form[name]?.value}
            onChange={handleChange}
            options={US_STATES}
            labelField="Name"
            valueField="Code"
            error={errorMessages[name]}
          />
        </StickySelectCustomWidth>
      ) : field.type === "ca_states" ? (
        <StickySelectCustomWidth
          customWidth={fieldWidth[field.width] || "full"}
        >
          <Select
            aria-label={field?.placeholder}
            placeHolder={field?.placeholder}
            name={name}
            value={form[name]?.state}
            onChange={handleChange}
            options={CA_STATES}
            labelField="Name"
            valueField="Code"
            error={errorMessages[name]}
          />
        </StickySelectCustomWidth>
      ) : (
        <StickyInputCustomWidth customWidth={fieldWidth[field.width] || "full"}>
          <Input
            key={index}
            label={(field?.placeholder as string) || field?.label}
            name={field?.key}
            value={form[name]?.value}
            onChange={handleChange}
            error={errorMessages[name]}
          />
        </StickyInputCustomWidth>
      )}
    </>
  );

  const stickyForm = (
    <>
      {success ? (
        <SuccessContainer>
          <SuccessImage src={successImage} />
          <SuccessHeadline>{successHeadline}</SuccessHeadline>
          <StickySuccessMessage>{successMessage}</StickySuccessMessage>
        </SuccessContainer>
      ) : (
        <>
          <Description
            textAlign={sticky}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          {currentStep === "step1" ? (
            <StepsContainer>
              <Step>
                <StepImage step={"enabled"}>1</StepImage>
                <StepLabel step={"enabled"}>{step1Label}</StepLabel>
              </Step>
              <Step>
                <StepImage step={"disabled"}>2</StepImage>
                <StepLabel step={"disabled"}>{step2Label}</StepLabel>
              </Step>
            </StepsContainer>
          ) : (
            <StepsContainer>
              <Step>
                <StepDisabled src={step1Disabled} />
                <StepLabel step={"enabled"}>{step1Label}</StepLabel>
              </Step>
              <Step>
                <StepImage step={"enabled"}>2</StepImage>
                <StepLabel step={"enabled"}>{step2Label}</StepLabel>
              </Step>
            </StepsContainer>
          )}
          <StickyForm onSubmit={handleSubmit} loading={loading}>
            {fields?.map((field: any, index: number) => {
              const name = field?.key;
              if (field.step === currentStep) {
                {
                  return stickyFields(field, name, index);
                }
              }
            })}
            {currentStep === "step2" && disclaimer && (
              <StickyCheckboxContainer>
                <Checkbox
                  name="default"
                  checked={disclaimerAccepted}
                  onChange={handleChangeDefault}
                  label={disclaimer}
                />
              </StickyCheckboxContainer>
            )}
            {currentStep === "step1" ? (
              <ButtonContainerNext>
                <StyledButton
                  onClick={nextStep}
                  type={ButtonType.PRIMARY_LIGHT}
                  label={labels?.next}
                />
              </ButtonContainerNext>
            ) : (
              <ButtonContainer>
                <StyledButton
                  onClick={previousStep}
                  type={ButtonType.PRIMARY_DARK}
                  label={labels?.back}
                />
                <StyledButton
                  disabled={disclaimer ? !disclaimerAccepted : false}
                  submit
                  type={ButtonType.PRIMARY_LIGHT}
                  label={buttonLabel || labels?.signUp}
                />
              </ButtonContainer>
            )}
          </StickyForm>
        </>
      )}
    </>
  );

  const defaultForm = (
    <>
      {success ? (
        <SuccessMessage>{successMessage}</SuccessMessage>
      ) : (
        <>
          <Title>{title}</Title>
          <Description
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <Form onSubmit={handleSubmit} loading={loading}>
            {fields?.map((field: any, index: number) => {
              const name = field?.key;
              if (field.type === "us_states")
                return (
                  <Select
                    key={index}
                    aria-label={field?.placeholder}
                    placeHolder={field?.placeholder}
                    name={name}
                    value={form[name]?.value}
                    onChange={handleChange}
                    options={US_STATES}
                    labelField="Name"
                    valueField="Code"
                    error={errorMessages[name]}
                  />
                );

              if (field.type === "ca_states")
                return (
                  <Select
                    aria-label={field?.placeholder}
                    placeHolder={field?.placeholder}
                    name={name}
                    value={form[name]?.state}
                    onChange={handleChange}
                    options={CA_STATES}
                    labelField="Name"
                    valueField="Code"
                    error={errorMessages[name]}
                  />
                );

              return (
                <Input
                  key={index}
                  label={(field?.placeholder as string) || field?.label}
                  name={field?.key}
                  value={form[name]?.value}
                  onChange={handleChange}
                  error={errorMessages[name]}
                />
              );
            })}
            {disclaimer && (
              <CheckboxContainer>
                <Checkbox
                  name="default"
                  checked={disclaimerAccepted}
                  onChange={handleChangeDefault}
                  label={disclaimer}
                />
              </CheckboxContainer>
            )}
            <StyledButton
              disabled={disclaimer ? !disclaimerAccepted : false}
              submit
              type={ButtonType.PRIMARY_LIGHT}
              label={buttonLabel || labels?.signUp}
            />
          </Form>
        </>
      )}
    </>
  );

  return (
    <>
      {sticky ? (
        <Floater
          heading={stickyLabel}
          collapseLabel={stickyLabel}
          form={stickyForm}
        />
      ) : (
        <Container id={slug} backgroundColor={backgroundColor}>
          {defaultForm}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.section<{ backgroundColor?: string }>`
  padding: ${vwMobile(80)} ${vwMobile(20)} ${vwMobile(40)};
  background-color: ${(p) => p.backgroundColor ?? "#012102"};

  ${MEDIA_DESKTOP} {
    padding: ${vwDesktop(80)} ${vwDesktop(200)};
  }
`;

const Title = styled.h1`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(44)};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.012em;
  line-height: ${rem(53)};
  margin-bottom: ${vwMobile(15)};
  text-align: center;

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(15)};
  }
`;

const CheckboxContainer = styled.div`
  grid-column: 1 / -1;
`;

const StickyCheckboxContainer = styled.div`
  grid-column: 1 / -1;

  div div input {
    color: #75c154;
    cursor: pointer;

    &:checked {
      color: #75c154 !important;
      background-color: #75c154 !important;
    }
  }
`;

const StyledButton = styled(Button)`
  ${MEDIA_DESKTOP} {
    margin-left: auto;
    width: 25%;
  }
`;

const Form = styled.form<{ loading?: boolean }>`
  display: grid;
  gap: ${vwMobile(20)};
  opacity: ${({ loading }) => (loading ? 0.5 : 1)} !important;
  pointer-events: ${({ loading }) => (loading ? "none" : "all")};

  ${MEDIA_DESKTOP} {
    grid-template-columns: 1fr 1fr;
    gap: ${vwDesktop(20)};

    ${StyledButton} {
      grid-column: 1 / 3;
    }
  }
`;

const StickyForm = styled.form<{ loading?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${vwMobile(20)};
  opacity: ${({ loading }) => (loading ? 0.5 : 1)} !important;
  pointer-events: ${({ loading }) => (loading ? "none" : "all")};
  flex-wrap: wrap;

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(10)};

    ${StyledButton} {
      grid-column: 1 / 3;
    }
  }
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 ${vwMobile(12)} 0;

  ${MEDIA_DESKTOP} {
    padding: 0 0 ${vwDesktop(12)} 0;
  }
`;

const Step = styled.div`
  display: flex;
  width: 50%;
  flex-direction: row;
  gap: ${vwMobile(10)};
  align-items: center;

  ${MEDIA_DESKTOP} {
    gap: ${vwDesktop(10)};
  }
`;

const StepImage = styled.p<{ step?: string }>`
  width: ${vwMobile(36)};
  height: ${vwMobile(36)};
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  color: ${({ step }) =>
    step === "enabled" ? "#012102" : "#7e7e7e"} !important;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  justify-content: center;
  padding: 0 !important;
  border-radius: 50px;
  border: 2px solid;
  border-color: ${({ step }) =>
    step === "enabled" ? "#75C154" : "#7e7e7e"} !important;
  background: ${({ step }) => (step === "enabled" ? "#75C154" : "transparent")};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(36)};
    height: ${vwDesktop(36)};
  }
`;

const StepDisabled = styled.img`
  width: ${vwMobile(40)};
  height: ${vwMobile(40)};
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding: 0 !important;

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(40)};
    height: ${vwDesktop(40)};
  }
`;

const StepLabel = styled.p<{ step?: string }>`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  width: 45%;
  color: ${({ step }) =>
    step === "enabled" ? "#012102" : "#7e7e7e"} !important;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.64px;
  text-transform: uppercase;
  padding: 0 !important;
`;

const StickySelectCustomWidth = styled.div<{ customWidth?: string }>`
  display: flex;
  height: auto;
  width: 100%;

  ${MEDIA_DESKTOP} {
    width: ${({ customWidth }) => (customWidth ? customWidth : "100%")};
  }

  div {
    width: 100%;
  }
`;

const StickyInputCustomWidth = styled.div<{ customWidth?: string }>`
  display: flex;
  width: 100%;

  ${MEDIA_DESKTOP} {
    width: ${({ customWidth }) =>
      customWidth ? customWidth : "100%"} !important;

    div {
      min-width: 100%;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: ${vwMobile(12)};

  ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: ${vwDesktop(12)};
  }

  button {
    ${MEDIA_DESKTOP} {
      width: 50%;
    }
  }
`;

const ButtonContainerNext = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: ${vwMobile(12)};

  ${MEDIA_DESKTOP} {
    flex-direction: row;
    gap: ${vwDesktop(12)};
  }

  button {
    ${MEDIA_DESKTOP} {
      width: 50%;
    }
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${vwMobile(50)} 0;

  ${MEDIA_DESKTOP} {
    padding: ${vwDesktop(150)} 0 ${vwDesktop(200)};
  }
`;

const SuccessImage = styled.img`
  width: ${vwMobile(75)};
  height: ${vwMobile(75)};
  margin: 0 0 ${vwMobile(20)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(75)};
    height: ${vwDesktop(75)};
    margin: 0 0 ${vwDesktop(20)};
  }
`;

const SuccessHeadline = styled.h2`
  color: #012102;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(31)};
  font-style: normal;
  font-weight: 900;
  line-height: ${rem(30)};
  letter-spacing: 1.24px;
  text-transform: uppercase;
  padding: 0 0 ${vwMobile(8)};

  ${MEDIA_DESKTOP} {
    font-size: ${rem(31)};
    padding: 0 0 ${vwDesktop(8)};
  }
`;

const Description = styled.p<{ textAlign?: boolean }>`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  font-weight: 600;
  letter-spacing: 0.012em;
  line-height: ${rem(22)};
  margin-bottom: ${vwMobile(40)};
  text-align: ${({ textAlign }) => (textAlign ? "left" : "center")};
  padding: 0 !important;

  ${MEDIA_DESKTOP} {
    margin: 0 ${vwDesktop(125)} ${vwDesktop(20)};
  }
`;

const SuccessMessage = styled.p`
  color: #ffffff;
  width: 100%;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  font-weight: 800;
  letter-spacing: 0.08em;
`;

const StickySuccessMessage = styled.p`
  color: #ffffff;
  width: 100%;
  text-align: left;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  font-weight: 600;
  font-style: normal;
  line-height: ${rem(22)};
  letter-spacing: 0.06em;
`;

export default DynamicForm;
