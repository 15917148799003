import React, { FC, useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import styled, { css } from "styled-components";
import SVG from "react-inlinesvg";

import Polygon from "../../assets/images/polygon.svg";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

export type CollapseMenuProps = {
  title: string;
  children: React.ReactNode;
  open: boolean;
  onFocus: (active: boolean) => void;
};

const CollapseMenu: FC<CollapseMenuProps> = ({
  title = "",
  children,
  open,
  onFocus,
}: CollapseMenuProps) => {
  const [forceClose, setForceClose] = useState(false);

  useEffect(() => {
    const tId = setTimeout(
      () => {
        setForceClose(!open);
      },
      open ? 0 : 400
    );

    return () => {
      clearTimeout(tId);
    };
  }, [open]);

  return (
    <Container
      onMouseEnter={() => onFocus(true)}
      onMouseLeave={() => onFocus(false)}
    >
      <StyledCollapsible
        easing={"ease-in"}
        forceClose={forceClose}
        transitionCloseTime={100}
        open={open}
        trigger={
          <CollapseTitleWrapper>
            <CollapseTitle>{title}</CollapseTitle>
            <Arrow src={Polygon} />
          </CollapseTitleWrapper>
        }
      >
        {children}
      </StyledCollapsible>
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
`;

const StyledCollapsible = styled(Collapsible)<{ forceClose: boolean }>`
  border-bottom: 4px solid #a55121;
  margin-bottom: ${toVWMobile(35)}vw;
  padding-bottom: ${toVWMobile(14)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin: 0 ${toVWDesktop(150)}vw ${toVWDesktop(35)}vw 0;
    padding-bottom: ${toVWDesktop(14)}vw;

    & .Collapsible__contentOuter {
      height: ${(p) => (p.forceClose ? 0 : "auto")};
    }
  }
`;

const CollapseTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CollapseTitle = styled.h3`
  color: #dd9271;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: normal;
  text-align: left;
  text-transform: uppercase;
  width: 90%;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(36)}rem;
    letter-spacing: ${toREM(1.08)}rem;
    line-height: ${toVWDesktop(40)}vw;
    width: 100%;
  }
`;

export const Arrow = styled(SVG)`
  margin-left: ${toVWMobile(8)}vw;
  width: ${toVWMobile(24)}vw;
  height: ${toVWMobile(14)}vw;
  fill: #dd9271;
  z-index: 1;
  transition: transform 0.5s;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
    margin-left: ${toVWDesktop(8)}vw;
    width: ${toVWDesktop(20)}vw;
    height: ${toVWDesktop(11)}vw;
  }
`;

export default CollapseMenu;
