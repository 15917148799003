import React from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type ImageWithTextProps = {
  isPublished?: boolean;
  title: string;
  description: string;
  image: {
    src: string;
    alt: string;
  };
  button: {
    label: string;
    link: string;
  };
};

export default function ImageWithText(
  props: ImageWithTextProps
): JSX.Element | null {
  if (!props?.isPublished) return null;

  return (
    <Container>
      <ImageContainer>
        <Image src={props?.image?.src} alt={props?.image?.alt} />
      </ImageContainer>
      <TextContainer>
        <Title dangerouslySetInnerHTML={{ __html: props.title }} />
        <Description dangerouslySetInnerHTML={{ __html: props.description }} />
        <StyledButton
          link={props?.button?.link}
          type={ButtonType.PRIMARY_LIGHT}
          label={props.button?.label}
        />
      </TextContainer>
    </Container>
  );
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${vwDesktop(60)};
  padding: ${vwMobile(41)} ${vwMobile(20)};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  color: #ffffff;
  background-color: #2b6447;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${vwDesktop(69)};
    height: ${vwDesktop(750)};
    padding: ${vwDesktop(52)} ${vwDesktop(130)};
  }
`;

const ImageContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(624)};
    height: ${vwDesktop(512)};
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TextContainer = styled.div`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(535)};
  }
`;

const Title = styled.h2`
  font-size: ${rem(48)};
  line-height: ${rem(56)};
  letter-spacing: 0.03em;
  font-weight: 900;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${rem(60)};
    line-height: ${rem(64)};
  }
`;

const Description = styled.p`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 600;
  margin-block: ${vwMobile(19)} ${vwMobile(58)};
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-block: ${vwDesktop(15)} ${vwDesktop(37)};
    width: ${vwDesktop(369)};
  }
`;

const StyledButton = styled(ButtonLink)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(180)};
  }
`;
