import React, { FC } from "react";
import styled from "styled-components";
import { MediaAsset } from "../../types";

import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import Link from "../shared/Link";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type FeaturedRecipeProps = {
  isDynamicSection?: boolean;
  slug?: string;
  isPublished?: boolean;
  picture: MediaAsset;
  pictureTitle: string;
  pictureSubtitle: string;
  recipeLink?: string;
  title: string;
  description: string;
  button: { label: string; link: string };
};

const FeaturedRecipe: FC<FeaturedRecipeProps> = ({
  isDynamicSection = false,
  slug,
  isPublished,
  picture,
  pictureTitle,
  pictureSubtitle,
  recipeLink,
  title,
  description,
  button,
}: FeaturedRecipeProps) => {
  if (
    (!picture && !pictureTitle && !pictureSubtitle && !title && !description) ||
    !isPublished
  ) {
    return null;
  }

  return (
    <Container id={slug} isDynamicSection={isDynamicSection}>
      <ContentWrapper isDynamicSection={isDynamicSection}>
        <LeftContent>
          <PictureContainer
            to={recipeLink}
            aria-label={`${pictureTitle} ${pictureSubtitle}`}
          >
            <Picture src={picture?.src} alt={picture?.alt} />
            <PictureBox>
              {pictureTitle && (
                <PictureSubtitle>{pictureTitle}</PictureSubtitle>
              )}
              {pictureSubtitle && (
                <PictureTitle>{pictureSubtitle}</PictureTitle>
              )}
            </PictureBox>
          </PictureContainer>
        </LeftContent>
        <RightContent>
          {title && <Title isDynamicSection={isDynamicSection}>{title}</Title>}
          <Description isDynamicSection={isDynamicSection}>
            {description}
          </Description>
          {button?.link && (
            <StyledButtonLink
              type={isDynamicSection && ButtonType.PRIMARY_DARK}
              link={button?.link}
              label={button?.label}
            />
          )}
        </RightContent>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.section<{ isDynamicSection?: boolean }>`
  background-color: ${({ isDynamicSection }) =>
    isDynamicSection ? "#ccb983" : "#304f4c"};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${({ isDynamicSection }) =>
      isDynamicSection && vwDesktop(160)};
    padding-bottom: ${({ isDynamicSection }) =>
      isDynamicSection && vwDesktop(90)};
  }
`;

const ContentWrapper = styled.div<{ isDynamicSection?: boolean }>`
  background-color: ${({ isDynamicSection }) =>
    isDynamicSection ? "#544423" : "#678e8b"};
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    max-width: ${toVWDesktop(1240)}vw;
    height: ${toVWDesktop(572)}vw;
    margin: 0 auto;
    position: relative;
    top: ${({ isDynamicSection }) => (isDynamicSection ? "0" : vwDesktop(-91))};
  }
`;

const LeftContent = styled.div`
  width: 100vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: ${toVWDesktop(105)}vw;
    width: 50%;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(37)}vw ${toVWMobile(24)}vw ${toVWMobile(89)}vw;
  width: 100vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(137)}vw ${toVWDesktop(100)}vw ${toVWDesktop(107)}vw;
    width: 50%;
  }
`;

const PictureContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-decoration: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${toVWDesktop(-65)}vw;

    &:hover {
      cursor: pointer;
    }
  }
`;

const PictureBox = styled.div`
  background-color: #ffffff;
  padding: ${toVWMobile(27)}vw ${toVWMobile(28)}vw;
  margin: ${toVWMobile(-60)}vw ${toVWMobile(12)}vw 0;
  width: calc(100% - ${toVWMobile(12 * 2)}vw);

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(456)}vw;
    max-width: ${toVWDesktop(456)}vw;
    margin: ${toVWDesktop(-70)}vw auto 0;
    padding: ${toVWDesktop(32)}vw ${toVWDesktop(25)}vw;
  }
`;

const Picture = styled.img`
  max-height: ${toVWMobile(505)}vw;
  height: ${toVWMobile(505)}vw;
  object-fit: cover;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    max-width: ${toVWDesktop(504)}vw;
    width: ${toVWDesktop(504)}vw;
    height: ${toVWDesktop(505)}vw;
  }
`;

const PictureTitle = styled.p`
  color: #000000;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  line-height: ${toREM(31)}rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(32)}rem;
    letter-spacing: 0.02em;
    line-height: ${toREM(42)}rem;
  }
`;

const PictureSubtitle = styled.h4`
  color: #000000;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(14)}rem;
  line-height: ${toREM(18)}rem;
  font-weight: 700;
  font-style: normal;
  margin-bottom: ${toVWMobile(5)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(18)}rem;
    letter-spacing: 0.08em;
    margin-bottom: ${toVWDesktop(5)}vw;
    line-height: ${toREM(24)}rem;
  }
`;

const Title = styled.h3<{ isDynamicSection?: boolean }>`
  color: ${({ isDynamicSection }) =>
    isDynamicSection ? "#ccb983" : "#000000"};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.04em;
  line-height: ${toREM(40)}rem;
  margin-bottom: ${toVWMobile(18)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(60)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(60)}rem;
    margin-bottom: ${toVWDesktop(18)}vw;
  }
`;

const Description = styled.p<{ isDynamicSection?: boolean }>`
  color: ${({ isDynamicSection }) =>
    isDynamicSection ? "#ffffff" : "#000000"};
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0;
  margin-bottom: ${toVWMobile(28)}vw;
  line-height: ${toREM(28)}rem;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(28)}vw;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-right: auto;
  }
`;

export default FeaturedRecipe;
