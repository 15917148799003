import React, { FC, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import Marquee from "react-fast-marquee";
import { shuffle } from "lodash";
import { getAssetURL } from "../../helpers/assets";
import Link from "../../components/shared/Link";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";
import useRightToLeft from "../../hooks/useRightToLeft";

export type OurImpactProps = {
  isPublished?: boolean;
  title: string;
  description: string;
  button: { label: string; link: string };
  benefitList: Benefit[];
  type: string;
  backgroundColor: string;
  staticImages: Image[];
};

type Benefit = {
  label_1: string;
  label_2: string;
};

type Image = {
  link: string;
  src: string;
  alt: string;
};

const OurImpact: FC<OurImpactProps> = ({
  isPublished,
  title,
  description,
  button,
  benefitList,
  backgroundColor,
  staticImages,
  type,
}: OurImpactProps) => {
  if (!isPublished) return null;

  const isRLT = useRightToLeft();

  const missionStyles = {
    padding: 300,
    fontColor: "#75c154",
    button: ButtonType.PRIMARY_LIGHT,
  };

  const impactStyles = {
    padding: 300,
    fontColor: "#033305",
    button: ButtonType.PRIMARY_DARK,
  };

  const largeStyles = {
    padding: 125,
    fontColor: "#ffffff",
    button: ButtonType.PRIMARY_LIGHT,
  };

  const [styles, setStyles] = useState(missionStyles);
  const [partnersArrayToUse, setPartnersArray] = useState<Image[]>([]);

  const duplicatePartners = (partnersShuffled = []) => {
    return [...partnersShuffled, ...partnersShuffled];
  };

  useMemo(() => {
    if (type !== null && type === "type1") {
      setStyles(impactStyles);
    }
    if (type !== null && type === "large_text") {
      setStyles(largeStyles);
    }
  }, [type]);

  useEffect(() => {
    const arrayShuffled = shuffle(staticImages);
    const partnersShuffleDuplicate = duplicatePartners(arrayShuffled);
    setPartnersArray(partnersShuffleDuplicate);
  }, [staticImages]);

  return (
    <Container backgroundColor={backgroundColor}>
      <TextContainer padding={styles.padding}>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Description
          isRTL={isRLT}
          margin={button?.link && button?.label ? 70 : 2}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        {button?.link && button?.label && (
          <ButtonLink
            className="button"
            link={button?.link}
            label={button?.label}
            type={styles.button}
          />
        )}
      </TextContainer>
      {staticImages?.length ? (
        <PartnersContainer>
          <Marquee direction={isRLT ? "right" : "left"} gradient={false}>
            {Object.keys(partnersArrayToUse).map((key: string) => (
              <Link
                to={partnersArrayToUse[key]?.image?.link}
                key={partnersArrayToUse[key]?.alt + key}
              >
                <Partner
                  src={partnersArrayToUse[key]?.image?.src}
                  alt={partnersArrayToUse[key]?.alt}
                />
              </Link>
            ))}
          </Marquee>
        </PartnersContainer>
      ) : (
        <StyledMarquee gradient={false} play={true} speed={40}>
          <BenefitWrapper>
            {benefitList?.map((benefit, index) => (
              <Benefit key={index}>
                <BenefitPercentage fontColor={styles.fontColor}>
                  {benefit?.label_1}
                </BenefitPercentage>
                <BenefitName fontColor={styles.fontColor}>
                  {benefit?.label_2}
                </BenefitName>
              </Benefit>
            ))}
          </BenefitWrapper>
        </StyledMarquee>
      )}
    </Container>
  );
};

const Container = styled.section<{ backgroundColor: string }>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#033305"};
  padding: ${toVWMobile(52)}vw 0 ${toVWMobile(57)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(95)}vw 0 ${toVWDesktop(72)}vw;
  }
`;

const StyledMarquee = styled(Marquee)`
  direction: ltr;
  overflow-y: hidden;
`;

const TextContainer = styled.div<{ padding?: number }>`
  padding: 0 ${toVWMobile(24)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${(props) =>
      props.padding
        ? `0 ${toVWDesktop(props.padding)}vw`
        : `0 ${toVWDesktop(300)}vw`};
  }
`;

const Title = styled.h3<{ fontColor?: string }>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;

  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: ${toVWMobile(56)}vw;
  margin-bottom: ${toVWMobile(15)}vw;
  text-align: center;
  text-transform: uppercase;

  &,
  p,
  span {
    font-weight: 900;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    letter-spacing: ${toREM(1.68)}rem;
    line-height: ${toVWDesktop(64)}vw;
    margin-bottom: ${toVWDesktop(15)}vw;
  }
`;

const Description = styled.p<{
  fontColor?: string;
  isRTL?: boolean;
  margin?: number;
}>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;
  margin-bottom: ${({ margin }) => toVWMobile(margin ?? 70)}vw;
  text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(24)}vw;
    margin-bottom: ${toVWDesktop(40)}vw;
    text-align: center;
  }
`;

const BenefitWrapper = styled.div`
  display: flex;
  padding-top: ${toVWMobile(61)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(81)}vw;
  }
`;

const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${toVWMobile(31)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 ${toVWDesktop(52)}vw;
  }
`;

const BenefitPercentage = styled.p<{ fontColor: string }>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(80)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(2.4)}rem;
  line-height: ${toVWMobile(70)}vw;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(120)}rem;
    letter-spacing: ${toREM(4.8)}rem;
    line-height: ${toVWDesktop(80)}vw;
    margin-bottom: ${toVWDesktop(10)}vw;
  }
`;

const BenefitName = styled.p<{ fontColor: string }>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(36)}rem;
    letter-spacing: ${toREM(1.08)}rem;
    line-height: ${toVWDesktop(40)}vw;
  }
`;

const PartnersContainer = styled.div`
  direction: ltr;
  padding: ${toVWMobile(53)}vw 0 ${toVWMobile(60)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(68)}vw 0 ${toVWDesktop(82.2)}vw;
  }
`;

const Partner = styled.img`
  width: ${toVWMobile(162)}vw;
  height: ${toVWMobile(162)}vw;
  margin-left: ${toVWMobile(26)}vw;
  object-fit: cover;
  display: inline-block;
  background-color: #ffffff;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(220)}vw;
    height: ${toVWDesktop(220)}vw;
    margin-left: ${toVWDesktop(36)}vw;
  }
`;

export default OurImpact;
