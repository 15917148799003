import React, { FC, useState, useCallback, useEffect } from "react";
import styled, { css } from "styled-components";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image } from "../../types";

import CollapseMenu, { Arrow } from "./CollapseMenu";

export type MeetFromPlantsProps = {
  isPublished?: boolean;
  title: string;
  benefitList: Benefit[];
  backgroundColor: string;
};

type Benefit = {
  title: string;
  description: string;
  image: Image;
};

const MeetFromPlants: FC<MeetFromPlantsProps> = ({
  isPublished,
  title,
  benefitList = [],
  backgroundColor,
}: MeetFromPlantsProps) => {
  const [activeTab, setActiveTab] = useState<null | number>(null);
  const [activeImg, setActiveImg] = useState<Image | null>(null);
  const [fontColor, setFontColor] = useState<null | string>(title);

  const regex = /color: #[0-9a-fA-F]{6}/;
  const match = fontColor?.match(regex);
  if (match) {
    const hexCode = match[0].split(":")[1].trim();
    setFontColor(hexCode);
  }

  if (!isPublished || !benefitList?.length) return null;

  const handleTabFocus = (index: number, focused: boolean) => {
    setActiveTab(focused ? index : null);
    handleImageFocus(index);
  };

  const handleImageFocus = (index: number) => {
    setActiveImg(benefitList[index]?.image);
  };

  const renderList = useCallback(() => {
    return benefitList?.map((benefit, index) => (
      <CollapseMenu
        key={index}
        open={activeTab === index}
        onFocus={(focused) => handleTabFocus(index, focused)}
        title={benefit?.title}
      >
        <Description>{benefit?.description}</Description>
      </CollapseMenu>
    ));
  }, [activeTab, handleTabFocus]);

  useEffect(() => {
    const [firtItem = {}] = benefitList;

    if (firtItem?.image) {
      setActiveImg(firtItem?.image);
    }
  }, []);

  return (
    <Container backgroundColor={backgroundColor}>
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <ContentWrapper>
        <Picture src={activeImg?.src} alt={activeImg?.alt} />
        <CollapseWrapper color={fontColor}>{renderList()}</CollapseWrapper>
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.section<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#7a3818"};
  padding: ${toVWMobile(52)}vw ${toVWMobile(24)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(101)}vw ${toVWDesktop(240)}vw ${toVWDesktop(170)}vw
      ${toVWDesktop(205)}vw;
  }
`;

const Title = styled.h3`
  color: #dd9271;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: ${toVWMobile(56)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(80)}rem;
    letter-spacing: ${toREM(2.4)}rem;
    line-height: ${toVWDesktop(88)}vw;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.theme.isRTL ? "flex-start" : "flex-end")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row-reverse;
    align-items: flex-start;
    margin-top: ${toVWDesktop(58)}vw;
  }
`;

const Picture = styled.img`
  width: ${toVWMobile(175)}vw;
  height: ${toVWMobile(179)}vw;
  margin-bottom: ${toVWMobile(30)}vw;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(55)}vw;
    width: ${toVWDesktop(400)}vw;
    height: ${toVWDesktop(400)}vw;
  }
`;

const CollapseWrapper = styled.div<{ color?: string }>`
  & .Collapsible {
    border-bottom: ${({ color }) =>
      color ? `4px solid ${color}` : "4px solid #a55121"};
    margin-bottom: ${toVWMobile(35)}vw;
    padding-bottom: ${toVWMobile(14)}vw;

    span div h3 {
      color: ${({ color }) => color ? color : "#a55121"};
    }

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding-bottom: ${toVWDesktop(14)}vw;
      margin: ${(props) =>
        props.theme.isRTL
          ? `0 0 ${toVWDesktop(35)}vw ${toVWDesktop(150)}vw`
          : `0 ${toVWDesktop(150)}vw ${toVWDesktop(35)}vw 0`};
    }

    .is-closed ${Arrow} {
      transform: rotate(180deg);
    }
  }
`;

const Description = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(28)}vw;
    margin: ${toVWDesktop(20)}vw 0 0;
  }
`;

export default MeetFromPlants;
