import isEmail from "validator/lib/isEmail";

type Field = {
  value: number | undefined | null | string;
  required?: boolean;
};
export type Form = { [key: string]: Field };
export type Messages = { [key: string]: string };

export const getErrorMessages = (form: Form, messages: Messages): Messages => {
  const getInitialErrorState = () => {
    const errors: { [key: string]: string } = {};
    for (const field in form) {
      errors[field] = "";
    }
    return errors;
  };
  const getRequiredFieldErrors = () => {
    const errors: { [key: string]: string } = {};
    for (const field in form) {
      if (!form[field]?.value && form[field]?.required) {
        errors[field] = messages.fieldRequired;
      }
    }
    return errors;
  };

  const getEmailFormatErrors = () => {
    const errors: { [key: string]: string } = {};
    for (const field in form) {
      const value = form[field]?.value;
      if (
        field.includes("email") &&
        typeof value === "string" &&
        !isEmail(value)
      ) {
        errors[field] = messages.validEmail;
      }
    }
    return errors;
  };

  return {
    ...getInitialErrorState(),
    ...getEmailFormatErrors(),
    ...getRequiredFieldErrors(),
  };
};

export const isFormValid = (form: Form, messages: Messages): boolean => {
  const errors = getErrorMessages(form, messages);
  let isValid = true;
  Object.values(errors).forEach((e) => {
    if (e) {
      isValid = false;
    }
  });
  return isValid;
};

export default {
  isFormValid,
  getErrorMessages,
};
