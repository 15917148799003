import React, { FC, useRef, useEffect } from "react";
import styled from "styled-components";
import ScrollContainer from "react-indiana-drag-scroll";
import { isMobile } from "react-device-detect";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import ButtonLink from "../shared/ButtonLink";

export type QuestionsAndAnswersProps = {
  slug?: string;
  title: string;
  cards: Card[];
  subtitle: string;
  button: { label: string; link: string };
};

type Card = {
  title: string;
  description: string;
};

const QuestionsAndAnswers: FC<QuestionsAndAnswersProps> = ({
  title,
  cards = [],
  subtitle,
  button,
  slug,
}: QuestionsAndAnswersProps) => {
  if (cards?.length === 0) {
    return null;
  }

  const scrollRef = useRef<HTMLElement | null>();
  const pauseScroll = useRef(false);
  const shouldAddOffset = (index: number): boolean => {
    return index % 2 === 0;
  };

  useEffect(() => {
    const iId = setInterval(
      () => {
        try {
          if (
            scrollRef?.current?.scrollLeft >=
              (scrollRef?.current?.scrollWidth / 3) * 2 ||
            scrollRef?.current?.scrollLeft === 0
          ) {
            scrollRef.current.scrollLeft = scrollRef?.current?.scrollWidth / 3;
            return;
          }
          if (!pauseScroll.current)
            scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + 1;
        } catch (e) {
          console.log(e);
        }
      },
      isMobile ? 30 : 10
    );
    return () => {
      clearInterval(iId);
    };
  }, []);

  return (
    <Container id={slug}>
      {title && <Title>{title}</Title>}

      <StyledContainer
        innerRef={(r) => (scrollRef.current = r)}
        activationDistance={0}
      >
        {[...cards, ...cards, ...cards, ...cards, ...cards]?.map(
          (card: CardType, i: number) => (
            <QuestionCard
              key={i}
              className={`${shouldAddOffset(i) ? "with-card-offset" : ""}`}
              onMouseEnter={() => (pauseScroll.current = true)}
              onMouseLeave={() => (pauseScroll.current = false)}
            >
              {card?.title && <QuestionTitle>{card?.title}</QuestionTitle>}
              <Answer
                dangerouslySetInnerHTML={{
                  __html: card?.description,
                }}
              />
            </QuestionCard>
          )
        )}
      </StyledContainer>
      <CtaWrapper>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {button?.label && (
          <ButtonLink label={button?.label} link={button?.link} />
        )}
      </CtaWrapper>
    </Container>
  );
};

const StyledContainer = styled(ScrollContainer)`
  direction: ltr;
  padding: 0 0 ${toVWMobile(111)}vw;
  display: flex;
  width: 100vw;
  gap: ${toVWMobile(20)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    gap: ${toVWDesktop(20)}vw;
    padding: 0 0 ${toVWDesktop(120)}vw;

    &:hover {
      cursor: grab;
    }
  }
`;

const Container = styled.section`
  background-color: #012102;
`;

const Title = styled.h2`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.00036em;
  line-height: ${toVWMobile(40)}vw;
  padding: ${toVWMobile(48)}vw ${toVWMobile(24)}vw ${toVWMobile(39)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(60)}rem;
    line-height: ${toVWDesktop(70)}vw;
    margin: 0 auto;
    padding: ${toVWDesktop(70)}vw ${toVWDesktop(24)}vw ${toVWDesktop(50)}vw;
    text-align: center;
    width: ${toVWDesktop(820)}vw;
  }
`;

const QuestionCard = styled.div`
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  background-color: #033305;
  height: ${toVWMobile(566)}vw;
  padding: ${toVWMobile(24)}vw ${toVWMobile(24)}vw ${toVWMobile(76)}vw;
  min-width: 80vw;
  margin-top: ${toVWMobile(30)}vw;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(40)}vw;
    height: ${toVWDesktop(566)}vw;
    padding: ${toVWDesktop(48)}vw ${toVWDesktop(48)}vw ${toVWDesktop(34)}vw;
    min-width: ${toVWDesktop(500)}vw;
  }

  &:hover {
    cursor: grab;
  }

  &.with-card-offset {
    position: relative;
    bottom: ${toVWMobile(-40)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      bottom: ${toVWDesktop(-40)}vw;
    }
  }
`;

const QuestionTitle = styled.h3`
  color: #75c154;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  line-height: ${toREM(31)}rem;
  margin-bottom: ${toVWMobile(32)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(40)}rem;
    margin-bottom: ${toVWDesktop(35)}vw;
    line-height: ${toVWDesktop(48)}rem;
  }
`;

const Answer = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(33)}vw;
  margin-bottom: ${toVWMobile(40)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;

  a {
    color: #ffffff;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(24)}rem;
    line-height: ${toVWDesktop(33)}vw;
    margin-bottom: ${toVWDesktop(40)}vw;
  }
`;

const CtaWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: ${toVWMobile(40)}vw ${toVWMobile(24)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    align-items: center;
    padding: ${toVWDesktop(20)}vw ${toVWDesktop(24)}vw ${toVWDesktop(80)}vw;
  }
`;

const Subtitle = styled(QuestionTitle)`
  color: #ffffff;
  letter-spacing: 0.03em;
  line-height: ${toVWMobile(40)}vw;
  margin-bottom: ${toVWMobile(27)}vw;
  text-transform: uppercase;
  font-size: ${toREM(24)}rem;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(70)}vw;
    margin-bottom: 0;
  }
`;

export default QuestionsAndAnswers;
