import { useEffect, useState } from "react";

export const useHashScroll = (slug: string): boolean => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const hash = window.location.hash;
      if (slug === hash?.substr(1)) {
        setActive(true);
        document.querySelector(hash)?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return active;
};
