import React, { FC } from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

import TriangleIcon from "../../assets/images/triangle_icon.svg";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { useHashScroll } from "../../hooks/useHashScroll";
import InlineSVG from "react-inlinesvg/esm";

export type CustomAccordionProps = {
  slug?: string;
  title: string;
  text: string;
  color?: string;
};

const CustomAccordion: FC<CustomAccordionProps> = ({
  slug,
  title,
  text,
  color,
}: CustomAccordionProps) => {
  const labels = useStaticLabels();
  const active = useHashScroll(slug);

  return (
    <StyledCollapsible
      color={color}
      easing={"ease-in"}
      open={active}
      transitionTime={300}
      trigger={
        <Element id={slug}>
          <Title
            color={color}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Icon
            src={TriangleIcon}
            alt={labels?.open}
            active={false}
            color={color}
          />
        </Element>
      }
      triggerWhenOpen={
        <Element id={slug}>
          <Title
            color={color}
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Icon src={TriangleIcon} alt={labels?.close} active color={color} />
        </Element>
      }
    >
      <Text
        dangerouslySetInnerHTML={{
          __html: text,
        }}
        color={color}
      />
    </StyledCollapsible>
  );
};

const StyledCollapsible = styled(Collapsible)<{ color?: string }>`
  border-bottom: 3px solid ${(p) => p.color ?? "#033305"};
`;

const Element = styled.section`
  display: flex;
  align-items: flex-start;
  padding-bottom: ${toVWMobile(14)}vw;
  margin-top: ${toVWMobile(34)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-bottom: ${toVWDesktop(15)}vw;
    margin-top: ${toVWDesktop(30)}vw;
    cursor: pointer;
  }
`;

const Title = styled.div<{ color?: string }>`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0.06em;
  line-height: normal;
  text-transform: uppercase;
  flex: 1;
  padding-right: ${toVWMobile(20)}vw;
  color: ${(p) => p.color ?? "inherit"};
`;

const Text = styled.div<{ color?: string }>`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toREM(28)}rem;
  padding-right: ${toVWMobile(64)}vw;
  border-bottom: 3px solid ${(p) => p.color ?? "#033305"};

  a {
    color: ${(p) => p.color ?? "#033305"};
  }

  p {
    margin-bottom: ${toVWMobile(26)}vw;
    color: ${(p) => p.color ?? "inherit"};

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: ${toVWDesktop(25)}vw;
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-right: ${toVWDesktop(44)}vw;
  }
`;

const Icon = styled(InlineSVG)<{
  active: boolean;
  color?: string;
}>`
  margin-top: ${toVWMobile(7)}vw;
  margin-right: ${toVWMobile(5)}vw;
  height: ${toVWMobile(20)}vw;
  transition: transform 300ms;
  transform: ${(props) => (props.active ? "rotate(270deg)" : "rotate(90deg)")};
  color: ${(p) => p.color ?? "inherit"};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(7)}vw;
    margin-right: ${toVWDesktop(4)}vw;
    height: ${toVWDesktop(20)}vw;
  }
`;

export default CustomAccordion;
