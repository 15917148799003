import { ReactNode } from "react";

export const getTabIndexFromHash = (
  tabs: { id?: string; title: string; children: ReactNode }[],
  hash: string
): number => {
  return tabs.findIndex((tab) =>
    tab?.id
      ? tab?.id === hash?.substr(1)?.toLowerCase()
      : tab?.title?.toLowerCase() === hash?.substr(1)?.toLowerCase()
  );
};
