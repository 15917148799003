import React, { FC, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import { vwDesktop, vwMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

import PlainHtml, { PlainHtmlProps } from "../PlainHtml";
import { CustomAccordion, CustomAccordionProps } from "../CustomAccordion";
import Tabs from "../Tabs";
import DynamicBanner, { DynamicBannerProps } from "../DynamicBanner";
import { DYNAMIC_TYPE, HTML_TEMPLATES } from "../../helpers/contants";
import DestiniLocator from "../DestiniLocator";
import Coupon from "../Coupon";
import {
  RecipesSection,
  RecipesSectionProps,
} from "../../components/RecipeLanding";
import FeaturedRecipe, {
  FeaturedRecipeProps,
} from "../../components/FeaturedRecipe";
import BlogHero, { BlogHeroType } from "../BlogHero";
import DynamicForm, { DynamicFormProps } from "../DynamicForm";
import DynamicSingleForm from "../DynamicSingleForm";
import QuestionsAndAnswers from "../QuestionsAndAnswers";
import DynamicBannerTemplate2 from "../DynamicBannerTemplate2";
import CardsSection from "../CardsSection";
import ImageWithText from "../ImageWithText";
import ReactPlayer from "react-player";
import WeBelieve from "../WeBelieve";
import MeetFromPlants from "../MeetFromPlants";
import OurImpact from "../OurImpact";
import EatWhatYouLove from "../EatWhatYouLove";
import TransparentImage from "../TransparentImage";
import SearchRecipe from "../SearchRecipe";
import DynamicCards from "../DynamicCards";
import AppendHtml from "../AppendHtml";

export type DynamicContainerProps = {
  sections: SectionType[];
};

export type SectionType = {
  type: string;
  slug: string;
  content:
    | PlainHtmlProps[]
    | AccordionType[]
    | TabsType[]
    | DynamicBannerProps
    | DynamicFormProps
    | undefined;
};

type AccordionType = {
  slug: string;
  content: CustomAccordionProps[];
};

type TabsType = {
  title: string;
  type: string;
  content: string | CustomAccordionProps[];
};

const DynamicContainer: FC<DynamicContainerProps> = ({
  sections,
}: DynamicContainerProps) => {
  let hash: string | null = null;
  if (typeof window !== "undefined") hash = window.location.hash;

  const getBackgroundColor = () => {
    if (typeof window !== "undefined") {
      return window?.location?.pathname === "/join-the-team"
        ? "#FFFFFF"
        : undefined;
    }
  };
  return (
    <>
      {sections?.map((section: SectionType) => {
        if (
          section?.type === DYNAMIC_TYPE.PLAIN_HTML &&
          (section?.content?.template == HTML_TEMPLATES.PLAIN_HTML_TYPE_1 ||
            !section?.content?.template)
        ) {
          return (
            <HtmlContainer1
              backgroundColor={
                section?.content?.backgroundColor || getBackgroundColor()
              }
            >
              <PlainHtml
                key={section?.slug}
                slug={section?.content?.slug}
                content={section?.content?.content}
              />
              {section?.content?.videoUrl && (
                <ReactPlayerContainer>
                  <StyledReactPlayer url={section?.content?.videoUrl} />
                </ReactPlayerContainer>
              )}
              {section?.content?.unsafeCode && (
                <AppendHtml html={section.content.unsafeCode}/>
              )}
            </HtmlContainer1>
          );
        }
        if (
          section?.type === DYNAMIC_TYPE.PLAIN_HTML &&
          section?.content?.template == HTML_TEMPLATES.PLAIN_HTML_TYPE_2
        ) {
          return (
            <HtmlContainer2
              backgroundColor={
                section?.content?.backgroundColor || getBackgroundColor()
              }
            >
              <PlainHtml
                key={section?.slug}
                slug={section?.content?.slug}
                content={section?.content?.content}
              />
              {section?.content?.videoUrl && (
                <ReactPlayerContainer>
                  <StyledReactPlayer url={section?.content?.videoUrl} />
                </ReactPlayerContainer>
              )}
            </HtmlContainer2>
          );
        }
        if (section?.type === DYNAMIC_TYPE.COUPON) {
          return <Coupon {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.DESTINI_LOCATOR) {
          return (
            <DestiniLocator
              isPublished={section?.content?.isPublished}
              autoSearch={section?.content?.autoSearch}
              destiniUrl={section?.content?.destiniUrl}
              country={section?.content?.country}
              language={section?.content?.language}
              backgroundColor={"#033305"}
            />
          );
        }
        if (section?.type === DYNAMIC_TYPE.ACCORDION) {
          return (
            !!section?.content?.length && (
              <Container>
                <AccordionContainer id={section?.slug}>
                  {section?.content?.map(
                    (accordion: CustomAccordionProps, index: number) => (
                      <CustomAccordion
                        key={index}
                        slug={accordion?.element_slug}
                        title={accordion?.title}
                        text={accordion?.description}
                      />
                    )
                  )}
                </AccordionContainer>
              </Container>
            )
          );
        }
        if (section?.type === DYNAMIC_TYPE.TABS) {
          return (
            !!section?.content?.length && (
              <Container>
                <StyledTabs
                  id={section?.slug}
                  key={section?.slug}
                  activeColor="#75c154"
                  color={"#FFFFFF"}
                  tabs={section?.content?.map((tabItem) => {
                    let activeTab = false;
                    const children = tabItem?.content?.map((section) =>
                      section?.type === DYNAMIC_TYPE.PLAIN_HTML ? (
                        <PlainHtml {...section} />
                      ) : (
                        section?.content?.map(
                          (accordion: CustomAccordionProps, index: number) => {
                            if (hash && !activeTab) {
                              activeTab =
                                accordion?.element_slug === hash?.substr(1);
                            }
                            return (
                              <CustomAccordion
                                key={index}
                                slug={accordion?.element_slug}
                                title={accordion?.title}
                                text={accordion?.description}
                                color={`#FFFFFF`}
                              />
                            );
                          }
                        )
                      )
                    );

                    return {
                      id: tabItem?.slug,
                      title: tabItem?.title,
                      children,
                      isActive: activeTab,
                    };
                  })}
                />
              </Container>
            )
          );
        }
        if (
          section?.type === DYNAMIC_TYPE.BANNER &&
          section?.content?.type == DYNAMIC_TYPE.BANNER_TYPE_1
        ) {
          return <DynamicBanner {...section} />;
        }
        if (section?.type === DYNAMIC_TYPE.FEATURED_RECIPES) {
          const [recipesStyles, setRecipesStyle] = useState({});

          useEffect(() => {
            setRecipesStyle(section?.content?.styles);
          }, []);

          return <RecipesSection {...section?.content} {...recipesStyles} />;
        }
        if (section?.type === DYNAMIC_TYPE.FEATURED_RECIPE) {
          return <FeaturedRecipe {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.FEATURED_CONTENT_SECTION) {
          return <FeaturedRecipe {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.FEATURED_ARTICLE) {
          return (
            <BlogHeroWrapper>
              <BlogHero {...section?.content?.hero} />
            </BlogHeroWrapper>
          );
        }
        if (section?.type === DYNAMIC_TYPE.MULTIPLE_FORM) {
          return <DynamicForm {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.SINGLE_FORM) {
          return <DynamicSingleForm {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.FAQ_SECTION) {
          return <QuestionsAndAnswers {...section?.content} />;
        }
        if (
          section?.type === DYNAMIC_TYPE.BANNER &&
          section?.content?.type == DYNAMIC_TYPE.BANNER_TYPE_2
        ) {
          return <DynamicBannerTemplate2 {...section} />;
        }
        if (section?.type === DYNAMIC_TYPE.CARDS_SECTION) {
          return <CardsSection {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.IMAGE_WITH_TEXT_SECTION) {
          return <ImageWithText {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.HERO_WITH_FIVE_IMAGES) {
          return <WeBelieve {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.ITEMS_COLLAPSIBLES) {
          return <MeetFromPlants {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.DYNAMIC_CAROUSEL) {
          return <OurImpact {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.TEXT_WITH_THREE_IMAGES) {
          return <EatWhatYouLove {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.TRANSPARENT_IMAGE) {
          return <TransparentImage {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.SEARCH_RECIPE) {
          return <SearchRecipe {...section?.content} />;
        }
        if (section?.type === DYNAMIC_TYPE.DYNAMIC_CARDS) {
          return <DynamicCards {...section?.content} />;
        }
      })}
    </>
  );
};

const Container = styled.div<{ backgroundColor?: string }>`
  padding: ${vwMobile(40)} ${vwMobile(20)};
  background-color: ${(p) => p.backgroundColor ?? "#033305"};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${vwDesktop(20)} ${vwDesktop(150)} ${vwDesktop(20)};
  }
`;

const HtmlContainer1 = styled.div<{ backgroundColor?: string }>`
  padding: ${vwMobile(40)} ${vwMobile(18)};
  background-color: ${(p) => p.backgroundColor ?? "#033305"};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${vwDesktop(80)} ${vwDesktop(150)} ${vwDesktop(80)};
  }
`;

const HtmlContainer2 = styled.div<{ backgroundColor?: string }>`
  padding: ${vwMobile(40)} ${vwMobile(18)};
  background-color: ${(p) => p.backgroundColor ?? "#033305"};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${vwDesktop(80)} ${vwDesktop(310)};
  }
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
`;

const ReactPlayerContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: ${vwMobile(77)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: 0;
  }
`;

const StyledTabs = styled(Tabs)`
  padding: 0 0 ${vwMobile(80)};

  && {
    background-color: transparent;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 0 ${vwDesktop(120)};
  }

  .tab-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${vwMobile(20)};
    background: transparent;
    padding: 0 0 ${vwMobile(40)};
    width: 100%;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      padding: 0 0 ${vwDesktop(64)};
    }
  }
`;

const AccordionContainer = styled.section``;

const BlogHeroWrapper = styled.div`
  background-color: #033305;
  & > div {
    margin-bottom: ${vwMobile(0)};
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 0 ${vwDesktop(100)} ${vwDesktop(100)};
  }
`;

export default DynamicContainer;
