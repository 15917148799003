import React, { FC, useState, useCallback, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { vwMobile, vwDesktop, rem } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { KlaviyoService } from "../../services/klaviyo";
import { FormstackService } from "../../services/formstack";
import Button, { ButtonType } from "../shared/Button";
import Input from "../shared/Input/Input";
import Checkbox from "../shared/Checkbox/Checkbox";
import { useMessages } from "../../hooks/useMessages";
import { getErrorMessages, isFormValid } from "../../helpers/forms";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { Floater } from "../../components/DynamicSingleForm/Floater";

export type DynamicFormProps = {
  slug?: string;
  isPublished: boolean;
  title?: string;
  field: {
    label: string;
    key: string;
    formstackFieldId: string;
    placeholder: string;
  };
  formName: string;
  klaviyoId: string;
  formstackId: string;
  buttonLabel: string;
  successMessage: string;
  disclaimer: string;
  sticky?: boolean;
  stickyLabel?: string;
};

const DynamicSingleForm: FC<DynamicFormProps> = ({
  slug,
  isPublished,
  title,
  field,
  klaviyoId,
  formName,
  formstackId,
  buttonLabel,
  successMessage,
  disclaimer,
  sticky,
  stickyLabel,
}: DynamicFormProps) => {
  const { label, key, formstackFieldId } = field;
  if (!isPublished) return null;
  const getInitialState = () => {
    return {
      [key]: {
        value: "",
        required: true,
        formstackFieldId,
      },
    };
  };

  const recaptchaRef = useRef();

  const [form, setForm] = useState(getInitialState());
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [success, setSuccess] = useState<boolean>(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const messages = useMessages();
  const labels = useStaticLabels();

  const handleChange = (event: React.FormEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    const newForm = { ...form };
    newForm[name].value = value;
    setForm(newForm);
  };

  const handleChangeDefault = (event: React.FormEvent) => {
    const { checked } = event.target as HTMLInputElement;
    setDisclaimerAccepted(checked);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setErrorMessages(getErrorMessages(form, messages));
      if (!isFormValid(form, messages)) return;

      try {
        setLoading(true);
        await recaptchaRef.current?.executeAsync();
        if (klaviyoId) {
          await KlaviyoService.submitDynamicForm(
            { listId: klaviyoId, formName },
            {
              ...form,
              ...(disclaimerAccepted ? { consent: { value: true } } : {}),
            }
          );
        }
        if (formstackId) {
          await FormstackService.submitDynamicForms({
            form,
            formstackId,
          });
        }
        const tId = setTimeout(() => {
          setSuccess(false);
          setForm(getInitialState());
          clearTimeout(tId);
        }, 10000);
        setSuccess(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [form, formName, messages, disclaimerAccepted]
  );

  const componentToRender = (
    <>
      {success ? (
        <SuccessMessage>{successMessage}</SuccessMessage>
      ) : (
        <>
          <Title>{title}</Title>
          <Form onSubmit={handleSubmit} loading={loading}>
            <Input
              label={(field?.placeholder as string) || (label as string)}
              name={key}
              value={form[key]?.value}
              onChange={handleChange}
              error={errorMessages[key]}
            />
            <StyledButton
              disabled={disclaimer ? !disclaimerAccepted : false}
              submit
              type={ButtonType.PRIMARY_LIGHT}
              label={buttonLabel || labels?.signUp}
            />
            {disclaimer && (
              <CheckboxContainer>
                <Checkbox
                  name="default"
                  checked={disclaimerAccepted}
                  onChange={handleChangeDefault}
                  label={disclaimer}
                />
              </CheckboxContainer>
            )}
          </Form>
        </>
      )}
    </>
  );

  return (
    <>
      {sticky ? (
        <Floater
          heading={stickyLabel}
          collapseLabel={stickyLabel}
          form={componentToRender}
        />
      ) : (
        <Container id={slug}>
          {componentToRender}
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.section`
  padding: ${vwMobile(80)} ${vwMobile(20)} ${vwMobile(40)};
  background-color: #012102;

  ${MEDIA_DESKTOP} {
    padding: ${vwDesktop(80)} ${vwDesktop(200)};
  }
`;

const Title = styled.h1`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(44)};
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.012em;
  line-height: ${rem(53)};
  margin-bottom: ${vwMobile(40)};
  text-align: center;

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(40)};
  }
`;

const StyledButton = styled(Button)`
  ${MEDIA_DESKTOP} {
    margin-left: auto;
    width: 100%;
    max-height: ${vwDesktop(54)};
  }
`;

const Form = styled.form<{ loading?: boolean }>`
  display: grid;
  gap: ${vwMobile(20)};
  opacity: ${({ loading }) => (loading ? 0.5 : 1)} !important;
  pointer-events: ${({ loading }) => (loading ? "none" : "all")};

  ${MEDIA_DESKTOP} {
    grid-template-columns: 3fr 1fr;
    gap: ${vwDesktop(20)};
  }
`;

const SuccessMessage = styled.p`
  color: #ffffff;
  width: 100%;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  font-weight: 800;
  letter-spacing: 0.08em;
`;

const CheckboxContainer = styled.div`
  grid-column: 1 / -1;
`;

export default DynamicSingleForm;
