import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import styled from "styled-components";
import {
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";
import { Link } from "../shared/Link";

export type DynamicCardsProps = {
  isPublished: boolean;
  title: string;
  text: string;
  backgroundColor: string;
  backgroundColorBottom: string;
  cardsBackgroundColor: string;
  hoverBackgroundColor: string;
  cards: card[];
};

type card = {
  image: string;
  image_alt: string;
  background_color: string;
  title: string;
  description: string;
  link: string;
  small_text: string;
  label_1: string;
  label_2: string;
};

const DynamicCards = ({
  isPublished,
  title,
  text,
  backgroundColor,
  backgroundColorBottom,
  cardsBackgroundColor,
  hoverBackgroundColor,
  cards,
}: DynamicCardsProps): JSX.Element | null => {
  if (!isPublished) return null;
  const [firstCard] = cards ?? [];

  return (
    <OurCommunitySectionContainer backgroundColor={backgroundColor}>
      <HeaderContainer>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Description
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </HeaderContainer>
      <AmbassadorsContainer>
        <Carousel>
          <CarouselSlider>
            <ScrollContainer vertical={false} className="scroll-container">
              <AmbassadorMemberSpacer />
              {cards?.map((card) => (
                <AmbassadorMember
                  key={card?.title + card?.background_color}
                  cardsBg={cardsBackgroundColor}
                  hoverBg={hoverBackgroundColor}
                >
                  <div className="bg" />
                  <div className="content">
                    <AmbassadorImage src={card?.image} alt={card?.image_alt} />
                    <AmbassadorText
                      dangerouslySetInnerHTML={{
                        __html: card?.description,
                      }}
                    />
                    <PlaceContainer className="place" to={card?.link}>
                      <FromName
                        dangerouslySetInnerHTML={{
                          __html: card?.title,
                        }}
                      />
                      <Where
                        dangerouslySetInnerHTML={{
                          __html: card?.small_text,
                        }}
                      />
                    </PlaceContainer>
                    <NameContainer className="name-container" to={card?.link}>
                      <Name
                        className="name"
                        dangerouslySetInnerHTML={{
                          __html: card?.label_1,
                        }}
                      />
                      <Name
                        className="name"
                        dangerouslySetInnerHTML={{
                          __html: card?.label_2,
                        }}
                      />
                    </NameContainer>
                  </div>
                </AmbassadorMember>
              ))}
              <AmbassadorMemberSpacer />
            </ScrollContainer>
          </CarouselSlider>
        </Carousel>
        <BottomBackground bgColor={backgroundColorBottom} />
      </AmbassadorsContainer>
    </OurCommunitySectionContainer>
  );
};

const OurCommunitySectionContainer = styled.section<{
  backgroundColor: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#1a5235"};
  position: relative;
`;

const BottomBackground = styled.div<{ bgColor?: string }>`
  width: 100%;
  height: ${toVWMobile(70)}vw;
  background-color: ${({ bgColor }) => bgColor ?? "#58250f"};
  position: absolute;
  bottom: 0%;
  z-index: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(39)}vw;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding-top: ${toVWMobile(44)}vw;
  padding-right: ${toVWMobile(24)}vw;
  padding-left: ${toVWMobile(24)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(80)}vw;
    padding-right: ${toVWDesktop(350)}vw;
    padding-left: ${toVWDesktop(350)}vw;
  }
`;

const Subtitle = styled.h3`
  width: 100%;
  display: flex;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 800;
  letter-spacing: 0.08em;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(24)}rem;
  text-transform: uppercase;
  justify-content: center;
`;

const Title = styled.h2`
  width: 100%;
  display: flex;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  letter-spacing: 0.03em;
  font-size: ${toREM(48)}rem;
  line-height: ${toREM(56)}rem;
  text-transform: uppercase;
  padding-top: ${toVWMobile(9)}vw;
  justify-content: center;
  text-align: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    line-height: ${toREM(64)}rem;
    padding-top: ${toVWDesktop(4)}vw;
  }
`;

const Description = styled.p`
  width: 100%;
  display: flex;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(28)}rem;
  padding-top: ${toVWMobile(8)}vw;
  text-align: center;
  justify-content: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(7)}vw;
  }
`;

const AmbassadorsContainer = styled.div`
  padding-top: ${toVWMobile(45)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(77)}vw;
  }
`;

const Carousel = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  & .slider {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const CarouselSlider = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-left: ${toVWMobile(12)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-left: ${toVWDesktop(0)}vw;
  }

  & .scroll-container {
    display: flex;
    overflow-x: scroll;
    gap: ${vwMobile(12)};

    ${MEDIA_DESKTOP} {
      gap: ${vwDesktop(20)};
    }
  }
`;

const AmbassadorMemberSpacer = styled.div`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: inline-block;
    min-width: ${toVWDesktop(186)}vw;
  }
`;

const AmbassadorMember = styled.div<{ cardsBg: string; hoverBg: string }>`
  cursor: grab;
  width: ${toVWMobile(327)}vw;
  height: ${vwMobile(730)};
  position: relative;
  color: white;

  .name {
    height: 0;
    overflow: hidden;
  }

  .name-container {
    opacity: 0;
  }

  ${MEDIA_DESKTOP} {
    width: ${toVWDesktop(610)}vw;
    height: ${toVWDesktop(920)}vw;

    &:hover {
      &,
      .name {
        height: auto;
        overflow: visible;
        color: #012102 !important;
        width: 100%;
      }

      .name-container {
        opacity: 1;
      }

      .place {
        display: none;
      }

      .arrow {
        display: flex;
        transform: translateX(${toVWDesktop(20)}vw);
      }

      .underline {
        display: flex;
        width: calc(100% + ${toVWDesktop(20)}vw);
      }

      .bg {
        background-color: ${(props) =>
          props.hoverBg ? props.hoverBg : "#75c154"};
        height: ${vwDesktop(535)};
      }
    }
  }

  .bg {
    background-color: ${(props) => (props.cardsBg ? props.cardsBg : "#012102")};
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    transition: all 0.2s ease-out;
    z-index: 1;
    height: ${vwMobile(558)};

    ${MEDIA_DESKTOP} {
      height: ${vwDesktop(758)};
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    padding-left: ${vwMobile(28)};
    padding-right: ${vwMobile(28)};
    padding-bottom: ${vwMobile(40)};
    height: 100%;

    ${MEDIA_DESKTOP} {
      padding-left: ${toVWDesktop(40)}vw;
      padding-right: ${toVWDesktop(40)}vw;
      padding-bottom: ${toVWDesktop(40)}vw;
    }
  }
`;

const AmbassadorImage = styled.img`
  object-fit: cover;

  width: ${vwMobile(288)};
  min-height: ${vwMobile(288)};
  height: ${vwMobile(288)};
  margin-bottom: ${vwMobile(32)};

  position: relative;
  left: ${vwMobile(-9)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    left: 0;
    width: ${vwDesktop(531)};
    min-height: ${vwDesktop(531)};
    height: ${vwDesktop(531)};
    margin-bottom: ${vwDesktop(28)};
  }
`;

const AmbassadorText = styled.p`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 500;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(27)}rem;
  letter-spacing: 0.04em;
  margin-bottom: ${vwMobile(41)};
  flex: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(23)}rem;
    line-height: ${toREM(32)}rem;
    letter-spacing: 0.02em;
    padding-left: ${toVWDesktop(19)}vw;
    padding-right: ${toVWDesktop(21)}vw;
    margin-bottom: ${vwDesktop(36)};
  }
`;

const NameContainer = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  text-decoration: none;

  ${MEDIA_DESKTOP} {
    padding-left: ${vwDesktop(19)};
    gap: ${vwDesktop(5)};
  }
`;

const Name = styled.div`
  color: #75c154;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  font-size: ${toREM(36)}rem;
  letter-spacing: 0.03em;
  line-height: ${toREM(40)}rem;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.2s ease-out;

  ${MEDIA_DESKTOP} {
    width: 0%;
    letter-spacing: 0.06em;
    border-bottom: ${vwDesktop(3)} solid currentColor;
    white-space: nowrap;
  }
`;

const PlaceContainer = styled(NameContainer)`
  flex-direction: column;
`;

const FromName = styled(Name)`
  color: #a2bebc;
`;

const Where = styled.p`
  display: flex;
  color: #a2bebc;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 500;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(24)}rem;
  text-align: left;
`;

export default DynamicCards;
