import React, { FC } from "react";
import styled, { css } from "styled-components";
import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image } from "../../types";
import { getLimits } from "../../helpers/parallax";
import { Parallax } from "react-scroll-parallax";

import ButtonLink from "../shared/ButtonLink";

export type EatWhatYouLoveProps = {
  isPublished?: boolean;
  backgroundColor: string;
  title: string;
  description: string;
  topImage: Image;
  midImage: Image;
  bottomImage: Image;
  button: { label: string; link: string };
};

const EatWhatYouLove: FC<EatWhatYouLoveProps> = ({
  isPublished,
  title,
  description,
  topImage,
  midImage,
  bottomImage,
  button,
  backgroundColor,
}: EatWhatYouLoveProps) => {
  if (!isPublished) return null;

  return (
    <Container backgroundColor={backgroundColor}>
      <ImagesContainer>
        <ImageParalax y={getLimits(["-100px", "50px"], ["-40px", "20px"])}>
          <TopImage src={topImage?.src} alt={topImage?.alt} />
        </ImageParalax>
        <ImageParalax y={getLimits(["-100px", "50px"], ["-40px", "20px"])}>
          <MidImage src={midImage?.src} alt={midImage?.alt} />
        </ImageParalax>
        <ImageParalax y={getLimits(["-100px", "50px"], ["-40px", "20px"])}>
          <BottomImage src={bottomImage?.src} alt={bottomImage?.alt} />
        </ImageParalax>
      </ImagesContainer>
      <TextContainer>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Description
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <ButtonLink link={button?.link} label={button?.label} />
      </TextContainer>
    </Container>
  );
};

const Container = styled.section<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#304f4c"};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: ${toVWMobile(578)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: auto;
    width: 45%;
    align-items: ${(props) => (props.theme.isRTL ? "flex-start" : "flex-end")};
  }
`;

const ImageParalax = styled(Parallax)``;

//Check component for wrong styles in mobile
const TopImage = styled.img`
  width: ${toVWMobile(155)}vw;
  height: ${toVWMobile(233)}vw;
  object-fit: contain;
  position: relative;
  top: ${toVWMobile(-12)}vw;
  right: ${(props) =>
    props.theme.isRTL ? `${toVWDesktop(-125)}vw` : `${toVWMobile(32)}vw`};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(205)}vw;
    height: ${toVWDesktop(307)}vw;
    right: ${(props) =>
      props.theme.isRTL ? `${toVWDesktop(254)}vw` : `${toVWDesktop(154)}vw`};
  }
`;

//Check component for wrong styles in mobile
const MidImage = styled.img`
  width: ${toVWMobile(286)}vw;
  height: ${toVWMobile(281)}vw;
  object-fit: contain;
  position: relative;
  top: ${toVWMobile(-52)}vw;
  right: ${(props) =>
    props.theme.isRTL ? `${toVWDesktop(-250)}vw` : `${toVWMobile(65)}vw;`};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(417)}vw;
    height: ${toVWDesktop(410)}vw;
    top: -5%;
    right: ${(props) => (props.theme.isRTL ? `${toVWDesktop(-100)}vw` : 0)};
  }
`;

const BottomImage = styled.img`
  width: ${toVWMobile(115)}vw;
  height: ${toVWMobile(173)}vw;
  object-fit: contain;
  position: relative;
  top: ${toVWMobile(-112)}vw;
  right: 0;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(146)}vw;
    height: ${toVWDesktop(220)}vw;
    top: -10%;
    right: ${(props) =>
      props.theme.isRTL ? `${toVWDesktop(340)}vw` : `${toVWDesktop(76)}vw`};
  }
`;

const TextContainer = styled.div`
  padding: 0 ${toVWMobile(24)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(191)}vw ${toVWDesktop(148)}vw ${toVWDesktop(152)}vw
      ${toVWDesktop(227)}vw;
    width: 55%;
  }
`;

const Title = styled.h3`
  color: #a2bebc;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: ${toVWMobile(56)}vw;
  margin-bottom: ${toVWMobile(15)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(56)}rem;
    letter-spacing: ${toREM(1.68)}rem;
    line-height: ${toVWDesktop(64)}vw;
    margin-bottom: ${toVWDesktop(15)}vw;
  }
`;

const Description = styled.p`
  color: #a2bebc;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(28)}vw;
  margin-bottom: ${toVWMobile(42)}vw;
  text-align: ${(props) => (props.theme.isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(24)}vw;
    margin-bottom: ${toVWDesktop(39)}vw;
  }
`;

export default EatWhatYouLove;
