import React, { FC } from "react";
import styled, { css } from "styled-components";

import { toVWMobile, toVWDesktop, toREM, vwMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import ButtonLink from "../shared/ButtonLink";
import { Image } from "../../types";

export type BlogHeroType = {
  isPublished?: boolean;
  title?: string;
  image: Image;
  post: string;
  date: string;
  description: string;
  button: { label: string; link: string };
};

const BlogHero: FC<BlogHeroType> = ({
  isPublished,
  title,
  image,
  post,
  date,
  description,
  button,
}: BlogHeroType) => {
  if (!isPublished) return null;

  return (
    <>
      {title && <Title>{title}</Title>}
      <Container>
        <Picture src={image?.src} alt={image?.alt} />
        <Content>
          <Post>{post}</Post>
          <Date>{date}</Date>
          <Description>{description}</Description>
          <ButtonLink label={button?.label} link={button?.link} />
        </Content>
      </Container>
    </>
  );
};

const Container = styled.div<{ isDynamicSection?: boolean }>`
  background-color: #012102;
  margin: ${toVWMobile(24)}vw 0 ${toVWMobile(80)}vw;
  padding: 0 ${toVWMobile(19)}vw ${toVWMobile(40)}vw;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: row-reverse;
    padding: ${toVWDesktop(40)}vw ${toVWDesktop(100)}vw ${toVWDesktop(65)}vw
      ${toVWDesktop(40)}vw;
    height: ${toVWDesktop(505)}vw;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Picture = styled.img`
  height: ${toVWMobile(224)}vw;
  position: relative;
  width: ${toVWMobile(337)}vw;
  object-fit: cover;
  top: ${toVWMobile(-24)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(536)}vw;
    min-width: ${toVWDesktop(715)}vw;
    width: ${toVWDesktop(715)}vw;
    top: unset;
    z-index: 5;
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Post = styled.div`
  color: #ccb983;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 800;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(24)}rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-right: ${toVWMobile(10)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-right: ${toVWDesktop(20)}vw;
  }
`;

const Date = styled(Post)`
  color: #ffffff;
`;

const Description = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-weight: 900;
  font-size: ${toREM(36)}rem;
  margin: ${toVWMobile(20)}vw 0 ${toVWMobile(40)}vw;
  line-height: ${toREM(40)}rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: ${toREM(56)}rem;
    line-height: ${toVWDesktop(64)}vw;
    margin: ${(props) =>
      props.theme.isRTL
        ? `${toVWDesktop(20)}vw 0 ${toVWDesktop(40)}vw ${toVWDesktop(80)}vw`
        : `${toVWDesktop(20)}vw ${toVWDesktop(80)}vw ${toVWDesktop(40)}vw 0`};
  }
`;

const Title = styled.h2`
  color: #75c154;
  font-size: ${toREM(44)}rem;
  font-weight: 900;
  letter-spacing: 0.012em;
  padding: ${toVWMobile(40)}vw ${toVWMobile(20)}vw ${toVWMobile(20)}vw;
  line-height: ${toREM(53)}rem;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(80)}vw 0 ${toVWDesktop(40)}vw;
    width: ${toVWDesktop(814)}vw;
    letter-spacing: 0.03em;
    line-height: ${toREM(64)}rem;
    font-size: ${toREM(60)}rem;
  }
`;

export default BlogHero;
