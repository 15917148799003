import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";
import Input from "../shared/Input";

export type CardsSectionProps = {
  isPublished?: boolean;
  title: string;
  cards: Card[];
};

type Card = {
  isPublished?: boolean;
  title: string;
  image: {
    src: string;
    alt: string;
  };
  placeholder: string;
  button: {
    label: string;
    link: string;
  };
};

export default function CardsSection(
  props: CardsSectionProps
): JSX.Element | null {
  if (!props?.isPublished) return null;

  const [inputValue, setInputValue] = useState<string>("");
  const handleChange = useCallback(
    (e) => {
      e.preventDefault();
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  return (
    <SectionContainer>
      <MobileBackground>
        <TitleContainer>
          <SectionTitle
            dangerouslySetInnerHTML={{
              __html: props.title,
            }}
          />
        </TitleContainer>
        <CardsContainer>
          {props.cards.map(
            (card) =>
              card.isPublished && (
                <Card key={card.title}>
                  <Title>{card.title}</Title>
                  <Image src={card.image.src} alt={card.image.alt} />
                  {card.placeholder ? (
                    <InputContainer>
                      <StyledInput
                        label={card.placeholder}
                        value={inputValue}
                        onChange={handleChange}
                      />
                      <SmallButton
                        label={card.button.label}
                        link={card.button.link + `?zip=${inputValue}`}
                        type={ButtonType.PRIMARY_LIGHT}
                      />
                    </InputContainer>
                  ) : (
                    <BigButton
                      label={card.button.label}
                      link={card.button.link}
                      type={ButtonType.PRIMARY_LIGHT}
                    />
                  )}
                </Card>
              )
          )}
        </CardsContainer>
        <DesktopBackground />
      </MobileBackground>
    </SectionContainer>
  );
}

const SectionContainer = styled.section`
  width: 100%;
`;

const MobileBackground = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #033305;
  position: relative;
  padding: ${vwMobile(40)} ${vwMobile(19)} 0 ${vwMobile(19)};
  z-index: 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${vwDesktop(80)} ${vwDesktop(203)} 0 ${vwDesktop(60)};
  }
`;

const DesktopBackground = styled.section`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #012102;
    position: absolute;
    z-index: 1;
    height: ${vwDesktop(370)};
    bottom: 0;
    left: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: 0 0 0 ${vwDesktop(40)};
  }
`;

const SectionTitle = styled.h2`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(48)};
  line-height: ${rem(52)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: 900;
  padding: 0 ${vwMobile(10)} 0 0;

  strong {
    color: #75c154;
    font-weight: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${rem(100)};
    line-height: ${rem(88)};
    padding: 0 ${vwDesktop(22)} 0 0;
  }
`;

const CardsContainer = styled.div`
  padding: ${vwMobile(50)} 0 0 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: row;
    padding: ${vwDesktop(78)} 0 0 0;
    z-index: 2;
    align-items: flex-end;
  }
`;

const Card = styled.div`
  padding: 0 0 ${vwMobile(43)} 0;

  //mobile only
  @media (max-width: ${BREAKPOINTS.DESKTOP}px) {
    &:last-child {
      padding-bottom: ${vwMobile(40)};
    }
  }
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    max-width: ${vwDesktop(395)};
    padding: 0 0 ${vwDesktop(78)} ${vwDesktop(40)};
  }
`;

const Title = styled.h3`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(36)};
  line-height: ${rem(40)};
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: 900;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${rem(48)};
    line-height: ${rem(56)};
  }
`;

const Image = styled.img`
  width: 100%;
  height: ${vwMobile(328)};
  object-fit: cover;
  padding: ${vwMobile(22)} 0 ${vwMobile(16)} 0;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(352)};
    height: ${vwDesktop(352)};
    padding: ${vwDesktop(23)} 0 ${vwDesktop(21)} 0;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(16)};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    gap: ${vwDesktop(20)};
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const SmallButton = styled(ButtonLink)`
  width: 100%;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${vwDesktop(180)};
    min-width: ${vwDesktop(85)};
  }
`;

const BigButton = styled(ButtonLink)`
  width: 100%;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    min-width: ${vwDesktop(85)};
  }
`;
