import React, { FC, useState } from "react";
import styled from "styled-components";
import { MediaAsset } from "../../types";
import {
  toVWMobile,
  toVWDesktop,
  toREM,
  vwDesktop,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import Link from "../shared/Link";
import { ROUTES } from "../../helpers/routes";
import { getLink, openLink } from "../../helpers/link";
import SearchInput from "../RecipeSearch/TextInput";

export type SearchRecipeProps = {
  slug?: string;
  isPublished?: boolean;
  picture: MediaAsset;
  pictureTitle: string;
  pictureSubtitle: string;
  recipeLink?: string;
  title: string;
  description: string;
  backgroundColor: string;
  button: { label: string; link: string };
};

const SearchRecipe: FC<SearchRecipeProps> = ({
  slug,
  isPublished,
  picture,
  pictureTitle,
  pictureSubtitle,
  recipeLink,
  title,
  description,
  backgroundColor,
}: SearchRecipeProps) => {
  if (
    (!picture && !pictureTitle && !pictureSubtitle && !title && !description) ||
    !isPublished
  ) {
    return null;
  }

  const [search, setSearch] = useState("");

  return (
    <Container id={slug} backgroundColor={backgroundColor}>
      <LeftContent>
        <PictureContainer
          to={recipeLink}
          aria-label={`${pictureTitle} ${pictureSubtitle}`}
        >
          <Picture src={picture?.src} alt={picture?.alt} />
          <PictureBox>
            {pictureSubtitle && <PictureTitle>{pictureSubtitle}</PictureTitle>}
          </PictureBox>
        </PictureContainer>
      </LeftContent>
      <TextContainer>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Description
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <InputBlock>
          <SearchInput
            placeholder="Search Recipe"
            value={search}
            onChange={(v) => setSearch(v as string)}
            onClose={() => {
              setSearch("");
            }}
            onKeyPress={(e) => {
              if (e.key !== "Enter") {
                return;
              }
              openLink(getLink(`${ROUTES.RECIPES_SEARCH}?q=${search}`));
            }}
          />
        </InputBlock>
      </TextContainer>
    </Container>
  );
};

const Container = styled.section<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#5F2B11"};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(80)}vw ${toVWDesktop(100)}vw ${toVWDesktop(80)}vw
      ${toVWDesktop(205)}vw;
    flex-direction: row-reverse;
    gap: ${toVWDesktop(70)}vw;
  }
`;

const LeftContent = styled.div`
  width: 100vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: ${toVWDesktop(610)}vw;
  }
`;

const PictureContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-decoration: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    top: ${toVWDesktop(-65)}vw;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }
`;

const PictureBox = styled.div`
  background-color: #ffffff;
  padding: ${toVWMobile(27)}vw ${toVWMobile(28)}vw;
  margin: ${toVWMobile(-60)}vw ${toVWMobile(12)}vw 0;
  width: calc(100% - ${toVWMobile(12 * 2)}vw);

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(456)}vw;
    max-width: ${toVWDesktop(456)}vw;
    margin: ${toVWDesktop(-70)}vw auto 0;
    padding: ${toVWDesktop(32)}vw ${toVWDesktop(25)}vw;
  }
`;

const Picture = styled.img`
  max-height: ${toVWMobile(505)}vw;
  height: ${toVWMobile(505)}vw;
  object-fit: cover;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    max-width: ${toVWDesktop(504)}vw;
    width: ${toVWDesktop(504)}vw;
    height: ${toVWDesktop(505)}vw;
  }
`;

const PictureTitle = styled.p`
  color: #000000;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  line-height: ${toREM(31)}rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(32)}rem;
    letter-spacing: 0.02em;
    line-height: ${toREM(42)}rem;
  }
`;

const TextContainer = styled.div`
  padding: ${toVWMobile(28)}vw ${toVWMobile(24)}vw ${toVWMobile(40)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 0;
  }
`;

const Title = styled.h3<{ fontColor?: string }>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(48)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.44)}rem;
  line-height: ${toVWMobile(44)}vw;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(80)}rem;
    letter-spacing: ${toREM(2.4)}rem;
    line-height: ${toVWDesktop(76)}vw;
    margin-bottom: ${toVWDesktop(15)}vw;
  }
`;

const Description = styled.p<{ fontColor?: string; isRTL?: boolean }>`
  color: ${(props) => props.fontColor};
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(24)}vw;
  margin-bottom: ${toVWMobile(28)}vw;
  text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(24)}vw;
    margin-bottom: ${toVWDesktop(40)}vw;
    text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};
  }
`;

const InputBlock = styled.div`
  width: 100%;
  margin-bottom: 0;

  input {
    bottom: 0;
  }
`;

export default SearchRecipe;
