import React, { FC } from "react";
import styled, { css } from "styled-components";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { Image } from "../../types";
import { useParallax } from "../../hooks/useParallax";
import {
  PARALLAX_CONTAINER_ID,
  PARALLAX_ELEMENTS,
  PARALLAX_ELEMENTS_ID,
} from "./parallax";
import useRightToLeft from "../../hooks/useRightToLeft";

export type WeBelieveProps = {
  isPublished?: boolean;
  title: string;
  subtitle: string;
  description: string;
  topBigImg: Image;
  topMediumImg: Image;
  topSmallImg: Image;
  bottomBigImg: Image;
  bottomSmallImg: Image;
  backgroundColor: string;
};

const WeBelieve: FC<WeBelieveProps> = ({
  isPublished,
  title,
  subtitle,
  description,
  topBigImg,
  topMediumImg,
  topSmallImg,
  bottomBigImg,
  bottomSmallImg,
  backgroundColor,
}: WeBelieveProps) => {
  const isRLT = useRightToLeft();

  if (!isPublished) return null;

  useParallax({
    elements: PARALLAX_ELEMENTS,
    containerId: PARALLAX_CONTAINER_ID,
  });

  const addStrongTagToTitle = (str) => {
    if (str.includes('&reg;')) {
        return str.replace(/&reg;/g, '<strong>&reg;</strong>');
    } else {
        return str;
    }
  }

  const modifiedTitle = addStrongTagToTitle(title) 

  return (
    <Container id={PARALLAX_CONTAINER_ID} backgroundColor={backgroundColor}>
      <Title
        isRTL={isRLT}
        dangerouslySetInnerHTML={{
          __html: modifiedTitle,
        }}
      />
      <TopImageContainer>
        <TopBigImg
          id={PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_1}
          src={topBigImg?.src}
          alt={topBigImg?.alt}
        />
        <TopMediumImg
          id={PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_2}
          src={topMediumImg?.src}
          alt={topMediumImg?.alt}
          isRTL={isRLT}
        />
        <TopSmallImg
          id={PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_3}
          src={topSmallImg?.src}
          alt={topSmallImg?.alt}
        />
      </TopImageContainer>
      <TextContainer isRTL={isRLT}>
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
        <Description
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </TextContainer>
      <BottomImageContainer>
        <BottomSmallImg
          id={PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_4}
          src={bottomSmallImg?.src}
          alt={bottomSmallImg?.alt}
        />
        <BottomBigImg
          id={PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_5}
          src={bottomBigImg?.src}
          alt={bottomBigImg?.alt}
        />
        <TopSmallImgDesktop
          id={PARALLAX_ELEMENTS_ID.OUR_MISSION_IMAGE_6}
          src={topSmallImg?.src}
          alt={topSmallImg?.alt}
        />
      </BottomImageContainer>
    </Container>
  );
};

const Container = styled.section<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#033305"};
  padding: ${toVWMobile(31)}vw 0 ${toVWMobile(52)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${toVWDesktop(74)}vw 0 ${toVWDesktop(143)}vw;
  }
`;

const Title = styled.h1<{ isRTL?: boolean }>`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(60)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.8)}rem;
  line-height: ${toVWMobile(60)}vw;
  padding: 0 ${toVWMobile(29)}vw 0 ${toVWMobile(22)}vw;
  position: relative;
  text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};
  text-transform: uppercase;
  z-index: 5;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    strong {
      font-size: ${toREM(85)}rem;
      position: relative;
      top: ${toVWDesktop(-30)}vw;
    }
    font-size: ${toREM(120)}rem;
    letter-spacing: ${toREM(3.6)}rem;
    line-height: ${toVWDesktop(110)}vw;
    margin-bottom: ${toVWDesktop(161)}vw;
    text-align: left;
  }
`;

const TopImageContainer = styled.div`
  padding: 0 ${toVWMobile(14)}vw;
  position: relative;
  top: ${toVWMobile(30)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0;
    position: absolute;
    top: ${toVWDesktop(172)}vw;
    right: 0;
  }
`;

const TopBigImg = styled.img`
  margin-bottom: ${toVWMobile(42)}vw;
  width: ${toVWMobile(347)}vw;
  height: ${toVWMobile(347)}vw;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-bottom: ${toVWDesktop(67)}vw;
    width: ${toVWDesktop(532)}vw;
    height: ${toVWDesktop(532)}vw;
  }
`;

const TopMediumImg = styled.img<{ isRTL?: boolean }>`
  width: ${toVWMobile(123)}vw;
  height: ${toVWMobile(185)}vw;
  object-fit: contain;
  position: absolute;
  bottom: ${toVWMobile(37)}vw;
  right: ${toVWMobile(25)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    position: unset;
    width: ${toVWDesktop(254)}vw;
    height: ${toVWDesktop(381)}vw;
    margin-right: ${toVWDesktop(100)}vw;

    ${({ isRTL }) => {
      return (
        isRTL &&
        css`
          margin: 0 0 0 ${toVWDesktop(120)}vw;
        `
      );
    }}
  }
`;

const TopSmallImg = styled.img`
  width: ${toVWMobile(110)}vw;
  height: ${toVWMobile(111)}vw;
  position: relative;
  bottom: 0;
  left: ${toVWMobile(41)}vw;
  object-fit: contain;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const TextContainer = styled.div<{ isRTL?: boolean }>`
  padding: ${toVWMobile(60)}vw ${toVWMobile(24)}vw ${toVWMobile(113)}vw;
  text-align: ${({ isRTL }) => (isRTL ? "right" : "left")};

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-left: ${toVWDesktop(120)}vw;
    padding: 0;
    min-height: fit-content;
    width: ${toVWDesktop(519)}vw;
    z-index: 5;

    ${({ isRTL }) => {
      return (
        isRTL &&
        css`
          margin: 0 ${toVWDesktop(120)}vw 0 0;
        `
      );
    }}
  }
`;

const Subtitle = styled.h2`
  color: #75c154;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(1.08)}rem;
  line-height: ${toVWMobile(40)}vw;
  margin-bottom: ${toVWMobile(28)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(80)}rem;
    letter-spacing: ${toREM(2.4)}rem;
    line-height: ${toVWDesktop(88)}vw;
    margin-bottom: ${toVWDesktop(21)}vw;
  }
`;

const Description = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: ${toVWMobile(46)}vw;

  a {
    color: #75c154;
  }
  
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    line-height: ${toVWDesktop(46)}vw;
    width: ${toVWDesktop(482)}vw;
  }
`;

const BottomImageContainer = styled.div`
  position: relative;
  width: ${toVWMobile(333)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(612)}vw;
    position: absolute;
    left: 0;
    top: ${toVWDesktop(610)}vw;
    width: ${toVWDesktop(467)}vw;
  }
`;

const BottomBigImg = styled.img`
  width: ${toVWMobile(333)}vw;
  height: ${toVWMobile(226)}vw;
  object-fit: contain;
  z-index: 1;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(467)}vw;
    height: ${toVWDesktop(316)}vw;
  }
`;

const BottomSmallImg = styled.img`
  width: ${toVWMobile(113)}vw;
  height: ${toVWMobile(173)}vw;
  object-fit: contain;
  position: absolute;
  right: ${toVWMobile(54)}vw;
  top: ${toVWMobile(-71)}vw;
  z-index: 2;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    width: ${toVWDesktop(188)}vw;
    height: ${toVWDesktop(288)}vw;
  }
`;

const TopSmallImgDesktop = styled(TopSmallImg)`
  display: none;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
    left: unset;
    right: ${toVWDesktop(32)}vw;
    width: ${toVWDesktop(167)}vw;
    height: ${toVWDesktop(168)}vw;
    bottom: ${toVWDesktop(-30)}vw;
    position: absolute;
    z-index: 5;
  }
`;

export default WeBelieve;
