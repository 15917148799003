import * as React from "react";
import styled from "styled-components";
import { useEffect, useMemo } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { FC } from "react";
import { useIntl } from "../../hooks/useIntl";
import { LOCALES } from "../../helpers/locales";

const CONTAINER_ID = "destini-container";

type DestiniLocatorProps = {
  destiniUrl?: string;
  autoSearch?: boolean;
  backgroundColor?: string;
  isPublished?: boolean;
  country?: string;
  language?: string;
};

const DestiniLocator: FC<DestiniLocatorProps> = ({
  destiniUrl,
  autoSearch = false,
  backgroundColor,
  country,
  language,
  isPublished,
}: DestiniLocatorProps) => {
  console.log({ country, language });
  const intl = useIntl();

  const url = destiniUrl || "https://destinilocators.com/beyondmeat";
  const [zip] = useQueryParam("zip", StringParam);
  const [product] = useQueryParam("product", StringParam);
  const [modal] = useQueryParam("modal", StringParam);
  const [search] = useQueryParam("search", StringParam);

  const queryParams = useMemo(() => {
    const params: { [key: string]: string } = {};
    if (zip) {
      params["ZIP"] = zip;
    }
    if (product && intl?.locale === LOCALES.EN_US) {
      //ToDo: Remove locale condition when destini is properly working.
      params["PROD"] = product;
    }
    if (autoSearch || search) {
      params["MM"] = "PANEL2";
    }
    if (modal) {
      params["ONS"] = "1";
    }
    if (country) {
      params["DEFCTRY"] = country;
    }
    if (language) {
      params["DEFLANG"] = language;
    }

    return Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
  }, [
    autoSearch,
    zip,
    product,
    modal,
    search,
    intl?.locale,
    country,
    language,
  ]);

  const loadScript = () => {
    const script = document.createElement("script");
    if (
      intl.locale === "en-US" ||
      intl.locale === "en-CA" ||
      intl.locale === "en-GB" ||
      intl.locale === "fr-CA"
    ) {
      script.src =
        "https://lets.shop/productFirstSnippet.js?id=destini-locator";
      script.setAttribute("charset", "utf-8");
    } else {
      script.src = `${url}/site/install/?${queryParams}`;
      script.type = "text/javascript";
    }

    const element = document.getElementById(CONTAINER_ID);
    if (element) {
      element.appendChild(script);
    }
  };

  const loadDiv = () => {
    const div = document.createElement("div");
    div.id = "destini-locator";
    div.className = "destini-locator-class";
    if (intl.locale === "en-US") {
      div.setAttribute("locator-id", "2444");
      div.setAttribute("alpha-code", "98C");
      div.setAttribute("locator-name", "Beyond Meat OCL Store Locator");
      div.setAttribute("client-id", "beyondmeat");
    } else if (intl.locale === "en-GB") {
      div.setAttribute("locator-id", "3030");
      div.setAttribute("alpha-code", "BD6");
      div.setAttribute("locator-name", "BeyondMeat UK OCL");
      div.setAttribute("client-id", "beyondmeatuk");
    } else if (intl.locale === "en-CA" || intl.locale === "fr-CA") {
      div.setAttribute("DEFCTRY", "");
      div.setAttribute("DEFLANG", "");
      div.setAttribute("locator-id", "2445");
      div.setAttribute("alpha-code", "98D");
      div.setAttribute("locator-name", "Beyond Meat Canada OCL Store Locator");
      div.setAttribute("client-id", "beyondmeatcanada");
    }
    div.style.width = "100%";
    div.style.height = "100vh";
    div.style.border = "none";
    div.style.height = "100%";
    div.style.overflow = "hidden";
    const element = document.getElementById(CONTAINER_ID);
    if (element) {
      element.appendChild(div);
    }
  };

  const loadIframe = () => {
    const iframe = document.createElement("iframe");
    iframe.id = "destini";
    iframe.style.width = "100%";
    iframe.src = `${url}/site/locator.php?${queryParams}`;
    iframe.style.height = "100vh";
    iframe.style.border = "none";
    iframe.height = "100vh";
    iframe.style.overflow = "hidden";
    const element = document.getElementById(CONTAINER_ID);
    if (element) {
      element.appendChild(iframe);
    }
  };

  useEffect(() => {
    loadScript();
    if (
      intl.locale === "en-US" ||
      intl.locale === "en-CA" ||
      intl.locale === "en-GB" ||
      intl.locale === "fr-CA"
    ) {
      loadDiv();
    } else {
      loadIframe();
    }
  }, []);

  if (!isPublished) return null;

  return <Container id={CONTAINER_ID} backgroundColor={backgroundColor} />;
};

const Container = styled.section<{ backgroundColor?: string }>`
  width: 100%;
  background-color: ${(p) => p.backgroundColor ?? "transparent"};
`;

export default DestiniLocator;
