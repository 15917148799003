import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type DynamicBannerProps = {
  type?: string;
  slug: string;
  content: ContentProps;
};

type ContentProps = {
  imageList: Image[];
  title: string;
  description: string;
  backgroundColor: string;
  titleFontSize: string;
  fontColor: string;
  button: {
    label: string;
    link: string;
  };
};

export default function DynamicBanner({
  slug,
  content,
  content: {
    imageList = [],
    title = "",
    description = "",
    backgroundColor = "",
    titleFontSize = "",
    fontColor = "",
    button = {},
  } = {},
}: DynamicBannerProps): JSX.Element | null {
  const [skewImg, setSkewImg] = useState(0);
  const [frontImg, setFrontImg] = useState(1);

  if (!content) return null;

  useEffect(() => {
    const int = setInterval(() => {
      if (skewImg < imageList?.length - 1) {
        setSkewImg((prev) => prev + 1);

        frontImg < imageList?.length - 1
          ? setFrontImg((prev) => prev + 1)
          : setFrontImg(0);
      } else {
        setSkewImg(0);
        setFrontImg(1);
      }
    }, 5 * 1000);

    return () => {
      clearInterval(int);
    };
  }, [skewImg, frontImg, setSkewImg, setFrontImg]);

  return (
    <Container id={slug} color={fontColor}>
      <Previews color={backgroundColor}>
        <Photos>
          {imageList?.map((img: Image, index: number) => (
            <Picture
              key={index}
              className={`${skewImg === index ? "rotated" : ""}
                  ${frontImg === index ? "front" : ""}`}
              src={img?.src}
              alt={img?.alt}
            />
          ))}
        </Photos>
      </Previews>
      <SellingText color={backgroundColor}>
        <Title
          titleFontSize={titleFontSize}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        {button?.link && (
          <ButtonContainer>
            <StyledButtonLink
              type={ButtonType.PRIMARY_LIGHT}
              link={button?.link}
              label={button?.label}
            />
          </ButtonContainer>
        )}
      </SellingText>
    </Container>
  );
}

const Container = styled.section<{ color?: string }>`
  display: flex;
  flex-flow: column;
  flex-direction: column;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  color: ${(p) => p.color ?? "#ffffff"};
  overflow: hidden;

  ${MEDIA_DESKTOP} {
    flex-flow: row-reverse;
    align-items: stretch;
    gap: 0;
    height: ${vwDesktop(764)};
  }
`;

const Column = styled.div<{ color?: string }>`
  background: ${(p) => p.color ?? "#012102"};
  width: 100%;
  padding: ${vwMobile(24)};

  ${MEDIA_DESKTOP} {
    width: 50%;
    padding: 0;
  }
`;

const Previews = styled(Column)`
  display: flex;
  justify-content: center;

  ${MEDIA_DESKTOP} {
    padding: ${(props) =>
      props.theme.isRTL
        ? `${vwDesktop(33)} 0 ${vwDesktop(76)} ${vwDesktop(88)}`
        : `${vwDesktop(33)} ${vwDesktop(88)} ${vwDesktop(76)} 0`};
  }
`;

const Picture = styled.img`
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  opacity: 0.5;

  transition: transform 0.3s ease-out, opacity 0.3s;
  z-index: 2;

  &.rotated {
    opacity: 1;
    z-index: 1;
    transform: rotate(-7deg);
    ${MEDIA_DESKTOP} {
      left: ${vwDesktop(-23)};
      top: ${vwDesktop(14)};
    }
  }

  &.front {
    opacity: 1;
    z-index: 5;
  }
`;

const Photos = styled.div`
  display: grid; // to stack them
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  align-items: center;
  justify-items: center;

  width: ${vwMobile(327)};
  height: ${vwMobile(327)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(532)};
    height: ${vwDesktop(532)};
  }
`;

const SellingText = styled(Column)`
  padding-bottom: ${vwMobile(40)};

  ${MEDIA_DESKTOP} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: ${(props) =>
      props.theme.isRTL
        ? `${vwDesktop(67)} ${vwDesktop(205)} ${vwDesktop(40)} 0`
        : `${vwDesktop(67)} 0 ${vwDesktop(40)} ${vwDesktop(205)}`};
  }
`;

const Title = styled.h1<{ titleFontSize?: number }>`
  font-size: ${rem(48)};
  letter-spacing: 0.03em;
  line-height: ${rem(52)};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: ${rem(19)};

  ${MEDIA_DESKTOP} {
    font-size: ${(p) => (p.titleFontSize ? rem(p.titleFontSize) : rem(100))};
    line-height: ${(p) => (p.titleFontSize ? rem(p.titleFontSize) : rem(100))};
    margin-bottom: ${rem(52)};
  }
`;

const Description = styled.p`
  font-size: ${rem(18)};
  font-weight: 600;
  line-height: ${rem(28)};
  letter-spacing: 0;
  margin-bottom: ${vwMobile(45)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(45)};
    width: 79%;
  }

  a,
  a:link {
    color: #75c154;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  ${MEDIA_DESKTOP} {
    display: flex;
    align-self: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: left;
`;
