import React from "react";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { Image } from "../../types";
import ButtonLink, { ButtonType } from "../shared/ButtonLink";

export type DynamicBannerTemplate2Props = {
  type?: string;
  slug: string;
  content: ContentProps;
};

type ContentProps = {
  imageList: Image[];
  title: string;
  description: string;
  backgroundColor: string;
  titleFontSize: number;
  fontColor: string;
  button: {
    label: string;
    link: string;
  };
};

const DynamicBannerTemplate2 = ({
  slug,
  content,
  content: {
    imageList = [],
    title = "",
    description = "",
    backgroundColor = "",
    titleFontSize = 0,
    fontColor = "",
    button,
  } = {},
}: DynamicBannerTemplate2): JSX.Element | null => {
  if (!content) return null;

  return (
    <>
      <Container id={slug} color={fontColor}>
        <Previews color={backgroundColor}>
          <Photos>
            <Picture src={imageList[0]?.src} alt={imageList[0]?.alt} />
          </Photos>
        </Previews>
        <SellingText color={backgroundColor}>
          <Title
            titleFontSize={titleFontSize}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          {button?.link && (
            <ButtonContainer>
              <StyledButtonLink
                type={ButtonType.PRIMARY_LIGHT}
                link={button?.link}
                label={button?.label}
              />
            </ButtonContainer>
          )}
        </SellingText>
      </Container>
    </>
  );
};

const Container = styled.div<{ color?: string }>`
  display: flex;
  flex-flow: column;
  flex-direction: column;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${rem(18)};
  color: ${(p) => p.color ?? "#ffffff"};
  overflow: hidden;

  ${MEDIA_DESKTOP} {
    flex-flow: row-reverse;
    align-items: stretch;
    gap: 0;
  }
`;

const Column = styled.div<{ color?: string }>`
  background: ${(p) => p.color ?? "#033305"};
  width: 100%;
  padding: ${vwMobile(24)};

  ${MEDIA_DESKTOP} {
    width: 50%;
    padding: 0;
  }
`;

const Previews = styled(Column)`
  display: flex;
  justify-content: center;
  padding: 0;

  ${MEDIA_DESKTOP} {
    padding: ${(props) =>
      props.theme.isRTL
        ? `0 0 ${vwDesktop(76)} ${vwDesktop(88)}`
        : `0 ${vwDesktop(88)} ${vwDesktop(76)} 0`};
  }
`;

const Photos = styled.div`
  display: grid; // to stack them
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  align-items: center;
  justify-items: center;

  width: 100%;
  height: ${vwMobile(327)};

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(532)};
    height: ${vwDesktop(532)};
  }
`;

const Picture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SellingText = styled(Column)`
  padding-bottom: ${vwMobile(40)};

  ${MEDIA_DESKTOP} {
    width: 50%;
    padding: ${(props) =>
      props.theme.isRTL
        ? `${vwDesktop(67)} ${vwDesktop(100)} ${vwDesktop(40)} 0`
        : `${vwDesktop(67)} 0 ${vwDesktop(40)} ${vwDesktop(100)}`};
  }
`;

const Title = styled.h1<{ titleFontSize?: number }>`
  font-size: ${rem(35)};
  letter-spacing: 0.03em;
  line-height: ${rem(40)};
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: ${rem(19)};

  ${MEDIA_DESKTOP} {
    font-size: ${(p) => (p.titleFontSize ? rem(p.titleFontSize) : rem(60))};
    line-height: ${(p) => (p.titleFontSize ? rem(p.titleFontSize) : rem(70))};
    margin-bottom: ${rem(10)};
  }
`;

const Description = styled.p`
  font-size: ${rem(18)};
  font-weight: 600;
  line-height: ${rem(28)};
  letter-spacing: 0;
  margin-bottom: ${vwMobile(45)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(45)};
    width: 79%;
  }

  a,
  a:link {
    color: #75c154;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: left;
`;

const StyledButtonLink = styled(ButtonLink)`
  ${MEDIA_DESKTOP} {
    display: flex;
    align-self: center;
  }
`;

export default DynamicBannerTemplate2;
