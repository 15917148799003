import { DYNAMIC_TYPE } from "./contants";
import { getAssetURL } from "./assets";
import { ROUTES } from "../helpers/routes";
import { Image } from "../types";
import { useIntl } from "../hooks/useIntl";
import { useStaticLabels } from "../hooks/useStaticLabels";
import { CouponProps } from "../components/Coupon";
import { getTranslations } from "../helpers/translations";
import { isPublished } from "../helpers/directus";
import { mapRecipeCard } from "../helpers/recipe";
import { formatDate } from "../helpers/date";

export const mapSections = (
  content: { collection: string; collection_id?: any; item?: any }[]
) =>
  content?.map(
    (section: { collection: string; collection_id?: any; item?: any }) => {
      return {
        slug:
          section?.collection_id?.element_slug || section?.item?.element_slug,
        type: section?.collection,
        content: mapContent(section),
      };
    }
  );

const mapCoupon = (section: {
  collection: string;
  collection_id: any;
}): CouponProps | null => {
  const intl = useIntl();
  const translatedCoupon = getTranslations(
    section,
    "collection_id.translations",
    intl.locale
  );

  if (!isPublished(translatedCoupon?.coupon_status)) return null;

  return {
    title: translatedCoupon?.title,
    findStoreLink: translatedCoupon?.find_store_link,
    image: {
      src: getAssetURL(translatedCoupon?.image),
      alt: translatedCoupon?.image_alt,
    },
    labels: {
      print: translatedCoupon?.print_label,
      findStore: translatedCoupon?.find_store_label,
    },
  };
};

const mapHtmlContent = (section: {
  collection: string;
  item?: any;
  collection_id?: any;
}) => {
  const intl = useIntl();
  const translatedHtml = getTranslations(
    section?.item || section?.collection_id,
    "translations",
    intl.locale
  );
  if (!isPublished(translatedHtml?.html_status)) {
    return undefined;
  }

  return {
    template: translatedHtml?.type,
    backgroundColor: translatedHtml?.background_color,
    content: translatedHtml?.content,
    videoUrl: translatedHtml?.video_url,
    unsafeCode: translatedHtml?.unsafe_code,
  };
};

const mapAccordionContent = (section: {
  collection: string;
  item?: any;
  collection_id?: any;
}) => {
  const intl = useIntl();
  const translatedAccordion = getTranslations(
    section?.item || section?.collection_id,
    "translations",
    intl.locale
  );
  return isPublished(translatedAccordion?.accordion_status)
    ? translatedAccordion?.elements
    : [];
};

const mapTabsContent = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedCollection = getTranslations(
    section,
    "collection_id.translations",
    intl.locale
  );
  return isPublished(translatedCollection?.tabs_section_status)
    ? translatedCollection.tabs?.map(({ dynamic_tab_section_id }) => {
        const translatedTabSection = getTranslations(
          dynamic_tab_section_id,
          "translations",
          intl.locale
        );
        return {
          title: translatedTabSection?.tab_title,
          slug: dynamic_tab_section_id?.tab_slug,
          content: mapSections(translatedTabSection?.tab_content),
        };
      })
    : [];
};

const mapBannerContent = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedBanner = getTranslations(
    section,
    "collection_id.translations",
    intl.locale
  );
  if (!isPublished(translatedBanner?.banner_status)) {
    return undefined;
  }

  return {
    title: translatedBanner?.title,
    description: translatedBanner?.description,
    backgroundColor: translatedBanner?.background_color,
    titleFontSize: translatedBanner?.title_font_size,
    fontColor: translatedBanner?.font_color,
    button: {
      label: translatedBanner?.button_label,
      link: translatedBanner?.button_link,
    },
    type: translatedBanner?.type,
    imageList: translatedBanner?.images
      ?.map(({ banner_asset_id }: any) => {
        const translatedImage = getTranslations(
          banner_asset_id,
          "translations",
          intl.locale
        );

        return {
          status: translatedImage?.banner_asset_status,
          src: getAssetURL(translatedImage?.image),
          alt: translatedImage?.image_alt,
        };
      })
      ?.filter(({ status }: { status: string }) => isPublished(status)),
  };
};

const mapDestiniLocatorContent = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedDestini = getTranslations(
    section,
    "collection_id.translations",
    intl.locale
  );

  return {
    isPublished: isPublished(translatedDestini?.destini_locator_status),
    destiniUrl: translatedDestini?.destini_override_url,
    autoSearch: !!translatedDestini?.auto_search,
    country: translatedDestini?.defctry,
    language: translatedDestini?.deflang,
  };
};

const mapFeaturedRecipes = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const labels = useStaticLabels();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    title: translatedData?.title,
    styles: {
      bgColor: translatedData?.background_color,
      headerColor: translatedData?.header_color,
      itemBgColor: translatedData?.card_background_color,
      itemTextColor: translatedData?.card_text_color,
      itemLabelColor: translatedData?.card_label_color,
      itemHoverBgColor: translatedData?.card_hover_background_color,
      itemHoverTextColor: translatedData?.card_hover_text_color,
    },
    link: {
      label: translatedData?.link_label,
      url: translatedData?.link_url,
    },
    recipeList: isPublished(translatedData?.status)
      ? translatedData?.recipes_list?.map(
          ({ recipe_page_id }: { recipe_page_id: any }) =>
            mapRecipeCard(recipe_page_id, labels, intl.locale)
        )
      : [],
  };
};

const mapFeaturedRecipe = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const {
    recipe = [],
    title,
    description,
    status,
  } = getTranslations(section, "collection_id.translations", intl.locale) ?? {};

  const translatedRecipePage =
    getTranslations(recipe, "translations", intl.locale) ?? {};

  const translatedRecipe =
    getTranslations(translatedRecipePage, "recipe.translations", intl.locale) ??
    {};

  return {
    isDynamicSection: true,
    slug: section?.collection_id?.element_slug,
    isPublished: isPublished(status),
    picture: {
      src: getAssetURL(translatedRecipe?.image),
      alt: translatedRecipe?.image_alt,
    },
    pictureTitle: labels?.featuredRecipes,
    pictureSubtitle: translatedRecipe?.title,
    recipeLink: `${ROUTES.RECIPES}/${recipe?.recipe_page_slug}`,
    title,
    description,
    button: {
      label: labels?.moreRecipes,
      link: ROUTES.RECIPES,
    },
  };
};

const mapFeaturedArticle = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const labels = useStaticLabels();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};

  const translatedMainBlogData = getTranslations(
    translatedData,
    "featured_article.translations",
    intl.locale
  );

  return {
    hero: {
      isPublished: isPublished(translatedData?.status),
      title: translatedData?.title,
      image: {
        src: getAssetURL(translatedMainBlogData?.article_image),
        alt: translatedMainBlogData?.article_image_alt,
      },
      thumbnail: {
        src: getAssetURL(translatedMainBlogData?.article_thumbnail),
        alt: translatedMainBlogData?.article_thumbnail_alt,
      },
      post: "Blog post,",
      date: formatDate(translatedMainBlogData?.article_date),
      description: translatedMainBlogData?.article_title,
      button: {
        label: labels?.readMoreLabel,
        link: `/whats-new/${translatedData?.featured_article?.article_slug}`,
      },
    },
  };
};

const mapMultipleForm = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedForm =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedForm?.status),
    title: translatedForm?.title,
    description: translatedForm?.description,
    backgroundColor: translatedForm?.background_color,
    disclaimer: translatedForm?.disclaimer,
    klaviyoId: translatedForm?.klaviyo_id,
    formstackId: translatedForm?.formstack_id,
    buttonLabel: translatedForm?.button_label,
    formName: translatedForm?.form_name,
    sticky: translatedForm.sticky ? translatedForm.sticky : false,
    stickyLabel: translatedForm?.sticky_label,
    step1Label: translatedForm?.step_1_title,
    step2Label: translatedForm?.step_2_title,
    successHeadline: translatedForm?.success_headline,
    fields: translatedForm?.fields?.map((field: any) => ({
      ...field,
      type: field?.type || 'textfield',
      formstackFieldId: field?.formstack_field_id,
    })),
    slug: section?.collection_id?.element_slug,
    successMessage: translatedForm?.success_message,
  };
};

const mapSingleForm = (section: { collection: string; collection_id: any }) => {
  const intl = useIntl();
  const translatedForm =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedForm?.status),
    title: translatedForm?.title,
    klaviyoId: translatedForm?.klaviyo_id,
    formstackId: translatedForm?.formstack_id,
    field: {
      key: translatedForm?.key,
      label: translatedForm?.label,
      formstackFieldId: translatedForm?.formstack_field_id,
      placeholder: translatedForm?.placeholder,
    },
    sticky: translatedForm.sticky ? translatedForm.sticky : false,
    stickyLabel: translatedForm?.sticky_label,
    formName: translatedForm?.form_name,
    buttonLabel: translatedForm?.button_label,
    slug: section?.collection_id?.element_slug,
    successMessage: translatedForm?.success_message,
    disclaimer: translatedForm?.disclaimer,
  };
};

const mapFAQSection = (section: { collection: string; collection_id: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};

  if (!isPublished(translatedData?.status)) return null;

  return {
    slug: section?.collection_id?.element_slug,
    title: translatedData?.title,
    subtitle: translatedData?.subtitle,
    button: {
      label: labels?.viewAllFaqs,
      link: ROUTES.FAQS,
    },
    cards: translatedData?.cards_list?.map(
      (card: { question: string; answer: string }) => ({
        title: card.question,
        description: card.answer,
      })
    ),
  };
};

const mapCardsSection = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};

  return {
    isPublished: isPublished(translatedData?.status),
    title: translatedData?.title,
    cards: translatedData?.cards
    ?.map(({ dynamic_card_id }: any) => {
      const translatedCard = getTranslations(
        dynamic_card_id,
        "translations",
        intl.locale
      );
      return {
        isPublished: isPublished(translatedCard?.status),
        title: translatedCard?.title,
        image: {
          src: getAssetURL(translatedCard?.image),
          alt: translatedCard?.image_alt,
        },
        placeholder: translatedCard?.input_placeholder,
        button: {
          label: translatedCard?.button_label,
          link: translatedCard?.button_link,
        },
      };
    })
  };
};

const mapImageWithTextSection = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedData?.status),
    title: translatedData?.title,
    description: translatedData?.description,
    image: {
      src: getAssetURL(translatedData?.image),
      alt: translatedData?.image_alt,
    },
    button: {
      label: translatedData?.button_label,
      link: translatedData?.button_link,
    },
  };
};

const mapFeaturedContentSection = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isDynamicSection: true,
    slug: section?.collection_id?.element_slug,
    isPublished: isPublished(translatedData?.status),
    picture: {
      src: getAssetURL(translatedData?.left_content_image),
      alt: translatedData?.left_content_image_alt,
    },
    pictureTitle: translatedData?.left_content_eyebrow,
    pictureSubtitle: translatedData?.left_content_title,
    title: translatedData?.right_content_title,
    description: translatedData?.right_content_description,
    button: {
      label: translatedData?.right_content_button_label,
      link: translatedData?.right_content_button_link,
    },
  };
};

const mapHeroWithFiveImages = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedData?.status),
    topBigImg: {
      src: getAssetURL(translatedData?.top_big_image),
      alt: translatedData?.top_big_image_alt,
    },
    topMediumImg: {
      src: getAssetURL(translatedData?.top_medium_image),
      alt: translatedData?.top_medium_image_alt,
    },
    topSmallImg: {
      src: getAssetURL(translatedData?.top_small_image),
      alt: translatedData?.top_small_image_alt,
    },
    bottomBigImg: {
      src: getAssetURL(translatedData?.bottom_big_image),
      alt: translatedData?.bottom_big_image_alt,
    },
    bottomSmallImg: {
      src: getAssetURL(translatedData?.bottom_medium_image),
      alt: translatedData?.bottom_medium_image_alt,
    },
    backgroundColor: translatedData?.background_color,
    title: translatedData?.title,
    subtitle: translatedData?.subtitle,
    description: translatedData?.description,
  };
};

const mapItemsCollapsibles = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedData?.status),
    backgroundColor: translatedData?.background_color,
    title: translatedData?.title,
    benefitList: translatedData?.accordion_elements
      ?.map((bListItem: any) => {
        const translatedPlant = getTranslations(
          bListItem,
          "meat_from_plants_asset_id.translations",
          intl.locale
        );
        return {
          ...translatedPlant,
          image: {
            src: getAssetURL(translatedPlant?.image),
            alt: translatedPlant?.image_alt,
          },
        };
      })
      ?.filter(({ status }: { status: string }) => isPublished(status)),
  };
};

const mapDynamicCarousel = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedData?.status),
    backgroundColor: translatedData?.background_color,
    type: translatedData?.styles,
    title: translatedData?.title,
    description: translatedData?.description,
    button: {
      label: translatedData?.cta_label,
      link: translatedData?.cta_link,
    },
    benefitList: translatedData?.carousel_elements,
    staticImages: translatedData?.images
        ?.map((bListItem: any) => {
          const translatedPlant = getTranslations(
            bListItem,
            "dynamic_carousel_assets_id.translations",
            intl.locale
          );
          return {
            ...translatedPlant,
            image: {
              link: translatedPlant?.link,
              src: getAssetURL(translatedPlant?.name),
              alt: translatedPlant?.alt,
            },
          };
        })
        ?.filter(({ status }: { status: string }) => isPublished(status)),
  };
};

const mapTextWithThreeImages = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedData?.status),
    topImage: {
      src: getAssetURL(translatedData?.top_image),
      alt: translatedData?.top_image_alt,
    },
    midImage: {
      src: getAssetURL(translatedData?.mid_image),
      alt: translatedData?.mid_image_alt,
    },
    bottomImage: {
      src: getAssetURL(translatedData?.bottom_image),
      alt: translatedData?.bottom_image_alt,
    },
    backgroundColor: translatedData?.background_color,
    title: translatedData?.title,
    description: translatedData?.description,
    button: {
      label: translatedData?.cta_label,
      link: translatedData?.cta_link,
    },
  };
};

const mapTransparentImage = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  return {
    isPublished: isPublished(translatedData?.status),
    bigImage: {
      src: getAssetURL(translatedData?.big_image),
      alt: translatedData?.big_image_alt,
    },
    transparentImage: {
      src: getAssetURL(translatedData?.transparent_image),
      alt: translatedData?.transparent_image_alt,
    },
    backgroundColor: translatedData?.background_color,
    title: translatedData?.title,
    type: translatedData?.styles,
    description: translatedData?.description,    
  };
};

const mapSearchRecipe = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const {
    recipe = [],
    background_color,
    title,
    description,
    status,
  } = getTranslations(section, "collection_id.translations", intl.locale) ?? {};

  const translatedRecipePage =
    getTranslations(recipe, "translations", intl.locale) ?? {};

  const translatedRecipe =
    getTranslations(translatedRecipePage, "recipe.translations", intl.locale) ??
    {};
  
  return {
    slug: section?.collection_id?.element_slug,
    isPublished: isPublished(status),
    picture: {
      src: getAssetURL(translatedRecipe?.image),
      alt: translatedRecipe?.image_alt,
    },
    pictureTitle: labels?.featuredRecipes,
    pictureSubtitle: translatedRecipe?.title,
    backgroundColor: background_color,
    recipeLink: `${ROUTES.RECIPES}/${recipe?.recipe_page_slug}`,
    title,
    description,
  };
};

const mapDynamicCards = (section: {
  collection: string;
  collection_id: any;
}) => {
  const intl = useIntl();
  const translatedData =
    getTranslations(section, "collection_id.translations", intl.locale) ?? {};
  const cards = (translatedData?.cards || [])
  .map(({ card_with_image_id }) => {
    const translatedCard = getTranslations(
      card_with_image_id,
      "translations",
      intl.locale
    );
    return {
      ...translatedCard,
      image: getAssetURL(translatedCard?.image),
    };
  })
  ?.filter(({ status }: { status: string }) => isPublished(status));

  return {
    isPublished: isPublished(translatedData?.status),
    title: translatedData?.title,
    text: translatedData?.additional_text,
    backgroundColor: translatedData?.background_color,
    backgroundColorBottom: translatedData?.background_color_bottom,
    cardsBackgroundColor: translatedData?.cards_background_color,
    hoverBackgroundColor: translatedData?.hover_background_color,
    cards,
  };
};


const mapContent = (section) => {
  if (section?.collection === DYNAMIC_TYPE.PLAIN_HTML) {
    return mapHtmlContent(section);
  }
  if (section?.collection === DYNAMIC_TYPE.ACCORDION) {
    return mapAccordionContent(section);
  }
  if (section?.collection === DYNAMIC_TYPE.TABS) {
    return mapTabsContent(section);
  }
  if (section?.collection === DYNAMIC_TYPE.DESTINI_LOCATOR) {
    return mapDestiniLocatorContent(section);
  }
  if (section?.collection === DYNAMIC_TYPE.BANNER) {
    return mapBannerContent(section);
  }
  if (section?.collection === DYNAMIC_TYPE.COUPON) {
    return mapCoupon(section);
  }
  if (section?.collection === DYNAMIC_TYPE.FEATURED_RECIPES) {
    return mapFeaturedRecipes(section);
  }
  if (section?.collection === DYNAMIC_TYPE.FEATURED_RECIPE) {
    return mapFeaturedRecipe(section);
  }
  if (section?.collection === DYNAMIC_TYPE.FEATURED_ARTICLE) {
    return mapFeaturedArticle(section);
  }
  if (section?.collection === DYNAMIC_TYPE.MULTIPLE_FORM) {
    return mapMultipleForm(section);
  }
  if (section?.collection === DYNAMIC_TYPE.SINGLE_FORM) {
    return mapSingleForm(section);
  }
  if (section?.collection === DYNAMIC_TYPE.FAQ_SECTION) {
    return mapFAQSection(section);
  }
  if (section?.collection === DYNAMIC_TYPE.FEATURED_CONTENT_SECTION) {
    return mapFeaturedContentSection(section);
  }
  if (section?.collection === DYNAMIC_TYPE.CARDS_SECTION) {
    return mapCardsSection(section);
  }
  if (section?.collection === DYNAMIC_TYPE.IMAGE_WITH_TEXT_SECTION) {
    return mapImageWithTextSection(section);
  }
  if (section?.collection === DYNAMIC_TYPE.HERO_WITH_FIVE_IMAGES) {
    return mapHeroWithFiveImages(section);
  }
  if (section?.collection === DYNAMIC_TYPE.ITEMS_COLLAPSIBLES) {
    return mapItemsCollapsibles(section);
  }
  if (section?.collection === DYNAMIC_TYPE.DYNAMIC_CAROUSEL) {
    return mapDynamicCarousel(section);
  }
  if (section?.collection === DYNAMIC_TYPE.TEXT_WITH_THREE_IMAGES) {
    return mapTextWithThreeImages(section);
  }
  if (section?.collection === DYNAMIC_TYPE.TRANSPARENT_IMAGE) {
    return mapTransparentImage(section);
  }
  if (section?.collection === DYNAMIC_TYPE.SEARCH_RECIPE) {
    return mapSearchRecipe(section);
  }
  if (section?.collection === DYNAMIC_TYPE.DYNAMIC_CARDS) {
    return mapDynamicCards(section);
  }

  return null;
};
