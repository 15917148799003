import React, { FC, useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

import { toVWMobile, toVWDesktop, toREM, vwMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { getTabIndexFromHash } from "./helpers";
import { nanoid } from "nanoid";

type TabType = {
  id?: string;
  title: string;
  children: React.ReactNode;
  tag?: string;
  isActive?: boolean;
};

export type TabsProps = {
  tabs: TabType[];
  withSearch?: boolean;
  className?: string;
  color?: string;
  activeColor?: string;
  id?: string;
  onTabChange?: (tab: number) => void;
};

const Tabs: FC<TabsProps> = (props: TabsProps) => {
  const getActiveTabByAccordion = () => {
    const index = props?.tabs.findIndex((tab) => tab?.isActive);
    return index >= 0 ? index : 0;
  };

  const [activeTab, setActiveTab] = useState(getActiveTabByAccordion());
  const id = useRef<string>(nanoid());

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const currentTabIndex = getTabIndexFromHash(props.tabs, hash);
      currentTabIndex >= 0 && setActiveTab(currentTabIndex);
    }
  }, []);

  const handleActiveTagChange = useCallback(
    (activeTabIndex: number) => {
      setActiveTab(activeTabIndex);
      props.onTabChange?.(activeTabIndex);
    },
    [setActiveTab, props.onTabChange]
  );

  return (
    <Container className={`${props.className ?? ""} container`} id={props?.id}>
      <TabContainer className="tab-container" role="tablist">
        {props.tabs?.map((tab, index) => {
          const CustomTag = `${
            tab?.tag || "div"
          }` as keyof JSX.IntrinsicElements;

          return (
            <CustomTag key={index}>
              <Tab
                id={tab?.id || `${id?.current}-${index}`}
                className="tab"
                onClick={() => handleActiveTagChange(index)}
                isActive={activeTab === index}
                role="tab"
                aria-selected={activeTab === index}
                tabindex="-1"
                color={props?.color}
                activeColor={props?.activeColor}
              >
                {tab?.title}
              </Tab>
            </CustomTag>
          );
        })}
      </TabContainer>

      {props.tabs?.map((tab, index) => {
        if (!tab?.children) {
          return null;
        }
        return (
          <TabContent
            key={index}
            tabindex={0}
            role="tabpanel"
            id={`${id?.current}-${index}-tab`}
            aria-labelledby={tab?.id || `${id?.current}-${index}`}
            hidden={activeTab === index}
            aria-hidden={activeTab === index}
          >
            {!!tab?.children && activeTab === index && tab?.children}
          </TabContent>
        );
      })}
    </Container>
  );
};

const Container = styled.section`
  background-color: #1e4320;
  display: flex;
  flex-direction: column;
`;

const TabContent = styled.div`
  width: 100%;
  display: contents;
`;

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${toVWMobile(20)}vw;
  background: transparent;
  padding: ${toVWMobile(50)}vw ${toVWMobile(9)}vw ${vwMobile(40)};
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    justify-content: center;
    padding: ${toVWDesktop(50)}vw 0 ${toVWDesktop(79)}vw;
  }
`;

const Tab = styled.button<{
  isActive?: boolean;
  color?: string;
  activeColor?: string;
}>`
  background: transparent;
  border: none;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(24)}rem;
    letter-spacing: 0.06em;
    line-height: ${toVWDesktop(29)}vw;
    height: ${toVWDesktop(43)}vw;
    padding-bottom: ${toVWDesktop(10)}vw;
  }

  color: ${({ isActive, color = "#ffffff", activeColor = "#75c154" }) =>
    isActive ? activeColor : color};
  cursor: pointer;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 900;
  height: ${toVWMobile(34)}vw;
  line-height: ${toVWMobile(22)}vw;
  letter-spacing: 0.04em;
  padding-bottom: ${toVWMobile(10)}vw;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  ${({ isActive, activeColor = "#75c154" }) =>
    isActive && `border-bottom: 3px solid ${activeColor}`}
`;

export default Tabs;
