import React, { ReactNode } from "react";
import InlineSVG from "react-inlinesvg/esm";
import styled from "styled-components";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import arrow from "./assets/arrowDown.svg";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import { useState } from "react";
import { useCallback } from "react";
import { animated, useTransition } from "react-spring";

export type FloaterProps = {
  heading: string;
  collapseLabel: string;
  form: ReactNode;
};

export function Floater(props: FloaterProps): JSX.Element {
  const [open, setOpen] = useState(false);

  const transitions = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200,
    },
  });

  const handleClick = useCallback(() => {
    setOpen((p) => !p);
  }, [setOpen]);

  return (
    <Container open={open}>
      {transitions(
        (styles, open) =>
          open && (
            <animated.div style={styles} className="form">
              {props.form}
            </animated.div>
          )
      )}
      <div className="inner" onClick={handleClick}>
        <div aria-hidden={open} className="closed">
          <p className="heading">{props.heading}</p>
          <InlineSVG src={arrow} />
        </div>
        <div aria-hidden={!open} className="opened">
          <span>{props.collapseLabel}</span>
          <InlineSVG src={arrow} style={{ transform: "rotate(-180deg)" }} />
        </div>
      </div>
    </Container>
  );
}

export default Floater;

const Container = styled.div<{ open: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(608)};
    right: ${vwDesktop(100)};
    padding: 0;
  }

  .form {
    overflow: auto;
    background-color: #fff;
    padding: 20px;
    > * {
      width: 100%;
    }

    h1 {
      color: #000;
    }

    div > div > div > input {
      color: #012102;
    }

    div > div > label {
      color: #012102;
    }

    p {
      color: #012102;
    }
  }

  .inner {
    height: ${vwMobile(64)};
    position: relative;
    display: flex;
    align-items: center;
    background-color: #75c154;
    cursor: pointer;
    flex-shrink: 0;

    ${MEDIA_DESKTOP} {
      padding: 0 ${vwDesktop(28)};
      height: ${vwDesktop(64)};
    }

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 ${vwMobile(28)};
      transition: all 0.4s;

      ${MEDIA_DESKTOP} {
        padding: 0 ${vwDesktop(28)};
      }

      &.opened {
        opacity: ${(p) => (p.open ? "1" : "0")};
        justify-content: flex-end;
        gap: ${vwMobile(16)};

        ${MEDIA_DESKTOP} {
          gap: ${vwDesktop(16)};
        }

        span {
          color: #000;
          font-size: ${rem(16)};
          font-weight: 800;
          text-transform: uppercase;
        }
      }
      &.closed {
        opacity: ${(p) => (p.open ? "0" : "1")};
      }
    }

    .heading {
      color: #000;
      font-size: ${rem(14)};
      font-weight: 900;
      text-transform: uppercase;
      ${MEDIA_DESKTOP} {
        font-size: ${rem(18)};
      }
    }

    svg {
      width: ${vwMobile(16)};
      height: ${vwMobile(9)};
      ${MEDIA_DESKTOP} {
        width: ${vwDesktop(16)};
        height: ${vwDesktop(9)};
      }
    }
  }
`;
