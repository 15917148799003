import React, { useLayoutEffect, useRef } from "react";

const AppendHtml = ({ html }: { html: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.innerHTML = html;

      const scripts =
        ref.current.querySelectorAll("script");
      scripts.forEach((script) => {
        const newScript = document.createElement("script");
        if (script.src) {
          newScript.src = script.src;
          newScript.onload = script.onload;
        } else {
          newScript.textContent = script.textContent;
        }
        script.remove();
        document.body.appendChild(newScript);
      });
    }
  }, [html]);

  return <div ref={ref}></div>;
};

export default AppendHtml;
