import React, { FC, useRef } from "react";
import styled from "styled-components";
import Loadable from "@loadable/component";
import { nanoid } from "nanoid";
import { Image } from "../../types";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../helpers/theme";
import { toREM, vwDesktop, vwMobile } from "../../helpers/styles";
import { ButtonLink } from "../shared/ButtonLink";
import { Button } from "../shared/Button";

export type CouponProps = {
  title: string;
  image: Image;
  labels: {
    print: string;
    findStore: string;
  };
  findStoreLink: string;
};

const LoadablePrintButton = Loadable(
  () => import("../shared/PrintButton/PrintButton")
);

const Coupon: FC<CouponProps> = ({
  title,
  image,
  labels,
  findStoreLink,
}: CouponProps) => {
  if (!title && !image && !labels && !findStoreLink) return null;

  const id = useRef<string>(nanoid());

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <CouponImage id={id?.current} src={image?.src} alt={image?.alt} />
      <Buttons>
        <LoadablePrintButton
          label={labels?.print}
          elementId={id?.current}
          src={image?.src}
        />
        <ButtonLink link={findStoreLink} label={labels?.findStore} />
      </Buttons>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  background: #ffffff;
  padding: ${vwMobile(40)} ${vwMobile(20)};
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(40)};

  ${MEDIA_DESKTOP} {
    padding: ${vwDesktop(75)} ${vwDesktop(210)};
    gap: ${vwDesktop(40)};
  }
`;

const Title = styled.p`
  color: #012102;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(44)}rem;
  letter-spacing: 0.03em;
  line-height: ${toREM(56)}rem;
  text-align: left;
  font-weight: 800;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(48)}rem;
    letter-spacing: 0.03em;
    line-height: ${toREM(56)}rem;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  ${MEDIA_DESKTOP} {
    width: fit-content;
  }
`;

const CouponImage = styled.img`
  width: 100%;
  height: auto;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${vwMobile(40)};
  justify-content: center;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    gap: ${vwDesktop(40)};
  }
`;

export default Coupon;
